import React, { useContext } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import Header from "./navigation/Header";
import Footer from './navigation/Footer';
import { MDBJumbotron  } from "mdbreact";
import {  useHistory , withRouter } from 'react-router-dom';
import { AuthContext } from '../App'

const CardExample = () => {
   const { currentUser } = useContext(AuthContext)

    const history = useHistory();

    const checkSignup = ()=>{

       history.push('/student/signup')
     }
    
     const checkStatus = ()=>{
      if( currentUser ){

        history.push('/student/plan')
      } else{
           history.push('/student/login')
        }

     } 

    





  return (
    <div>
    <Header/>
    <MDBJumbotron className="bannerbg">
            <h2 className=' font-weight-bold mb-0 pt-md-3 text-center text-white'>
                  Plans & pricing
                </h2>
                {/* <h5 className='display-6 mb-0 pt-md-2 text-center text-white'>
                Quickly build an effective pricing table for your potential customers with this layout.
          It&apos;s built with default Material-UI components with little customization.</h5> */}
   </MDBJumbotron>
    <div  className='container mt-5'>
    <MDBRow >
      <MDBCol md='3' className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard>
        <MDBCardBody className="paddingg">
          <MDBCardHeader className="text-center font-weight-bold mb-3">5 minute/ ₹0
          <p>Actual price: ₹0</p> 
            <p><mark>Discount@NA</mark></p>
          </MDBCardHeader>
          
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Free video call for 5 minutes through a referral code 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn    onClick={()=>checkSignup()}   className="instibuddy-bg"  style={{ display:'flex', justifyContent:'center' }}>Sign Up</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol md='3' className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
          <MDBCardHeader className="text-center font-weight-bold mb-3">1 session/₹99
          <p>Actual price: ₹200</p> 
            <p><mark>Discount@50%</mark></p>
          </MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  1 video call with a Mentor for Unlimited time duration
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg"   onClick={()=>checkStatus()}  style={{ display:'flex', justifyContent:'center' }}>Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol md='3' className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
          <MDBCardHeader className="text-center font-weight-bold mb-3">3 sessions/ ₹249
          <p>Actual price: ₹600 </p> 
            <p><mark>Discount@58%</mark></p></MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  3 video calls with a Mentor for Unlimited time duration 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg"   onClick={()=>checkStatus()}   style={{ display:'flex', justifyContent:'center' }}>Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol md='3' className="mt-2" style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
          <MDBCardHeader className="text-center font-weight-bold mb-3">5 sessions/ ₹349
          <p>Actual price: ₹1000 </p> <p><mark>Discount@65%</mark></p></MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  5 video calls with a Mentor for Unlimited time duration 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg ml-2 mr-2"   onClick={()=>checkStatus()}  style={{ display:'flex', justifyContent:'center' }}>Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    </MDBRow>
    
    </div>
    <Footer/>
    </div>
  )
}

export default withRouter(CardExample);