import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Key from "../../image/news/Key.png";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            Unofficial Answer key of JEE Advanced Released
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className="mb-4">
                  <img className="m-auto" src={Key} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
                <p className=" mb-lg-0 mb-md-5 mb-4">
                  JEE Advanced 2020 was conducted on Sunday, September 27.
                  Several coaching institutions have released unofficial answer
                  keys of JEE Advanced 2020. The answer key contains answers to
                  all the questions asked in Paper 1 and Paper 2, solved by the
                  coaching centre staff, and can be used to calculate probable
                  scores in the IIT admission test. However, an official answer
                  key will be released soon at jeeadv.ac.in. JEE Advanced 2020
                  Question Papers The authorities will consider the official
                  answer key for calculating JEE Advanced scores and therefore,
                  candidates are advised to download it as and when available.
                </p>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                <a
                  href="https://engineering.careers360.com/articles/jee-advanced-answer-key"
                  className="text-danger"
                >
                  <u>Download unofficial JEE Advanced Answer Key 2020 here.</u>{" "}
                </a>
                <h3>
                  How To Download JEE Advanced Answer Key 2020 (Unofficial) By
                  Various Organisations:
                </h3>
                <ul className="ml-3">
                  <li>Go to the link mentioned above.</li>
                  <li>On the table, select the coaching centre and paper.</li>
                  <li>Download the unofficial JEE Advanced answer key.</li>
                </ul>
                IIT Delhi, conducting body of JEE Advanced 2020, is likely to
                release the provisional answer key tomorrow at jeeadv.ac.in.
                Candidates will be given the opportunity to challenge the
                provisional answer key and after hearing feedback from students,
                the final answer key will be released. "The provisional answer
                keys for both Paper 1 and Paper 2 will be displayed on the JEE
                (Advanced) 2020 online portal. After the display of provisional
                answer keys, the candidates may submit their feedback, if any,
                on the candidate portal, after considering which a final answer
                key will be displayed on the website, as per the following
                schedule and the marks will be awarded in accordance with the
                final answer key," an official statement said.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
