import React from "react";
import Footer from "../navigation/Footer";
import { MDBJumbotron } from "mdbreact";
import Header from "../navigation/Header";

const News = () => {
  return (
    <div>
      <Header />
      <div>
        <MDBJumbotron className="bannerbg">
          <h2 className="font-weight-bold mb-0 pt-md-3 text-center text-white">
            Terms and Conditions For Mentor
          </h2>
        </MDBJumbotron>
        <div className="container">
          <p>Welcome to instibuddy.in!</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Instibuddy App's Website, located at instibuddy.in.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use instibuddy.in if you do not agree
            to take all of the terms and conditions stated on this page.
          </p>

          <ul className="ml-3">
            <li>No use of foul language.</li>
            <li>
              Mentor should not share his personal details with Students due to
              privacy concerns.
            </li>
            <li>
              Mentor makes no commitment to update or correct any Information
              that appears on the Internet or on this web site.
            </li>
            <li>
              Mentor makes no representations or warranties as to the
              completeness or accuracy of Information.
            </li>
            <li>Promotion of any third party product is not allowed.</li>
            <li>Any confidential information of this app must not leak.</li>
            <li>
              Payment would be given only for the paid mentorship session.
            </li>
            <li>
              Your payment is based on rating for each call as follow:
              <ul>
                <li>1-1.6: ₹10</li>
                <li> 1.6-2.2: ₹15</li>
                <li> 2.2-2.8: ₹20</li>
                <li> 2.8-3.3: ₹25</li>
                <li>3.3-3.8: ₹30</li>
                <li>3.8-4.3: ₹35</li>
                <li>4.3-4.8: ₹40</li>
                <li>4.8-5.0: ₹45</li>
                <li>5.0: ₹50</li>
              </ul>
            </li>
            <li>
              Google form for payment would only be sent after 1hr of successful
              mentorship.
            </li>
            <li>
              Mentorship certificate would be sent once you complete 2hrs of
              successful mentorship
            </li>
            <li>
              These terms shall be governed by and constructed in accordance
              with the laws of India without reference to conflict of laws
              principles and disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of the courts of
              Gopalganj,Bihar.
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default News;
