import React from 'react';
import { MDBDataTable, MDBContainer } from 'mdbreact';

const DatatablePrank = () => {
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 200
      },
      
      {
        label: 'Joining date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Rank',
        field: 'rank',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Category Rank',
        field: 'categoryRank',
        sort: 'asc',
        width: 100
      }
    ],
    rows: [
      {
        name: 'Tiger Nixon',
        email: 'System Architect',
        mobile: 'Edinburgh',
        rank: '61',
        date: '2011/04/25',
        categoryRank: '320'
      },
      {
        name: 'Garrett Winters',
        email: 'Accountant',
        mobile: 'Tokyo',
        rank: '63',
        date: '2011/07/25',
        categoryRank: '170'
      },
      {
        name: 'Ashton Cox',
        email: 'Junior Technical Author',
        mobile: 'San Francisco',
        rank: '66',
        date: '2009/01/12',
        categoryRank: '86'
      },
      {
        name: 'Cedric Kelly',
        email: 'Senior Javascript Developer',
        mobile: 'Edinburgh',
        rank: '22',
        date: '2012/03/29',
        categoryRank: '433'
      },
      {
        name: 'Airi Satou',
        email: 'Accountant',
        mobile: 'Tokyo',
        rank: '33',
        date: '2008/11/28',
        categoryRank: '162'
      },
      {
        name: 'Brielle Williamson',
        email: 'Integration Specialist',
        mobile: 'New York',
        rank: '61',
        date: '2012/12/02',
        categoryRank: '372'
      },
      {
        name: 'Herrod Chandler',
        email: 'Sales Assistant',
        mobile: 'San Francisco',
        rank: '59',
        date: '2012/08/06',
        categoryRank: '137'
      },
      {
        name: 'Rhona Davidson',
        email: 'Integration Specialist',
        mobile: 'Tokyo',
        rank: '55',
        date: '2010/10/14',
        categoryRank: '327'
      },
      {
        name: 'Colleen Hurst',
        email: 'Javascript Developer',
        mobile: 'San Francisco',
        rank: '39',
        date: '2009/09/15',
        categoryRank: '205'
      },
      {
        name: 'Sonya Frost',
        email: 'Software Engineer',
        mobile: 'Edinburgh',
        rank: '23',
        date: '2008/12/13',
        categoryRank: '103'
      },
      {
        name: 'Jena Gaines',
        email: 'mobile Manrankr',
        mobile: 'London',
        rank: '30',
        date: '2008/12/19',
        categoryRank: '90'
      },
      {
        name: 'Quinn Flynn',
        email: 'Support Lead',
        mobile: 'Edinburgh',
        rank: '22',
        date: '2013/03/03',
        categoryRank: '342'
      },
      {
        name: 'Charde Marshall',
        email: 'Regional Director',
        mobile: 'San Francisco',
        rank: '36',
        date: '2008/10/16',
        categoryRank: '470'
      },
      {
        name: 'Haley Kennedy',
        email: 'Senior Marketing Designer',
        mobile: 'London',
        rank: '43',
        date: '2012/12/18',
        categoryRank: '313'
      },
      {
        name: 'Tatyana Fitzpatrick',
        email: 'Regional Director',
        mobile: 'London',
        rank: '19',
        date: '2010/03/17',
        categoryRank: '385'
      },
      {
        name: 'Michael Silva',
        email: 'Marketing Designer',
        mobile: 'London',
        rank: '66',
        date: '2012/11/27',
        categoryRank: '198'
      },
      {
        name: 'Paul Byrd',
        email: 'Chief Financial mobiler (CFO)',
        mobile: 'New York',
        rank: '64',
        date: '2010/06/09',
        categoryRank: '725'
      },
      {
        name: 'Gloria Little',
        email: 'Systems Administrator',
        mobile: 'New York',
        rank: '59',
        date: '2009/04/10',
        categoryRank: '237'
      },
      {
        name: 'Bradley Greer',
        email: 'Software Engineer',
        mobile: 'London',
        rank: '41',
        date: '2012/10/13',
        categoryRank: '132'
      },
      {
        name: 'Dai Rios',
        email: 'Personnel Lead',
        mobile: 'Edinburgh',
        rank: '35',
        date: '2012/09/26',
        categoryRank: '217'
      },
      {
        name: 'Jenette Caldwell',
        email: 'Development Lead',
        mobile: 'New York',
        rank: '30',
        date: '2011/09/03',
        categoryRank: '345'
      },
      {
        name: 'Yuri Berry',
        email: 'Chief Marketing mobiler (CMO)',
        mobile: 'New York',
        rank: '40',
        date: '2009/06/25',
        categoryRank: '675'
      },
      {
        name: 'Caesar Vance',
        email: 'Pre-Sales Support',
        mobile: 'New York',
        rank: '21',
        date: '2011/12/12',
        categoryRank: '106'
      },
      {
        name: 'Doris Wilder',
        email: 'Sales Assistant',
        mobile: 'Sidney',
        rank: '23',
        date: '2010/09/20',
        categoryRank: '85'
      },
      {
        name: 'Angelica Ramos',
        email: 'Chief Executive mobiler (CEO)',
        mobile: 'London',
        rank: '47',
        date: '2009/10/09',
        categoryRank: '1'
      },
      {
        name: 'Gavin Joyce',
        email: 'Developer',
        mobile: 'Edinburgh',
        rank: '42',
        date: '2010/12/22',
        categoryRank: '92'
      },
      {
        name: 'Jennifer Chang',
        email: 'Regional Director',
        mobile: 'Singapore',
        rank: '28',
        date: '2010/11/14',
        categoryRank: '357'
      },
      {
        name: 'Brenden Wagner',
        email: 'Software Engineer',
        mobile: 'San Francisco',
        rank: '28',
        date: '2011/06/07',
        categoryRank: '206'
      },
      {
        name: 'Fiona Green',
        email: 'Chief Operating mobiler (COO)',
        mobile: 'San Francisco',
        rank: '48',
        date: '2010/03/11',
        categoryRank: '850'
      },
      {
        name: 'Shou Itou',
        email: 'Regional Marketing',
        mobile: 'Tokyo',
        rank: '20',
        date: '2011/08/14',
        categoryRank: '163'
      },
      {
        name: 'Michelle House',
        email: 'Integration Specialist',
        mobile: 'Sidney',
        rank: '37',
        date: '2011/06/02',
        categoryRank: '95'
      },
      {
        name: 'Suki Burks',
        email: 'Developer',
        mobile: 'London',
        rank: '53',
        date: '2009/10/22',
        categoryRank: '114'
      },
      {
        name: 'Prescott Bartlett',
        email: 'Technical Author',
        mobile: 'London',
        rank: '27',
        date: '2011/05/07',
        categoryRank: '145'
      },
      {
        name: 'Gavin Cortez',
        email: 'Team Leader',
        mobile: 'San Francisco',
        rank: '22',
        date: '2008/10/26',
        categoryRank: '235'
      },
      {
        name: 'Martena Mccray',
        email: 'Post-Sales support',
        mobile: 'Edinburgh',
        rank: '46',
        date: '2011/03/09',
        categoryRank: '324'
      },
      {
        name: 'Unity Butler',
        email: 'Marketing Designer',
        mobile: 'San Francisco',
        rank: '47',
        date: '2009/12/09',
        categoryRank: '85'
      },
      {
        name: 'Howard Hatfield',
        email: 'mobile Manrankr',
        mobile: 'San Francisco',
        rank: '51',
        date: '2008/12/16',
        categoryRank: '164'
      },
      {
        name: 'Hope Fuentes',
        email: 'Secretary',
        mobile: 'San Francisco',
        rank: '41',
        date: '2010/02/12',
        categoryRank: '109'
      },
      {
        name: 'Vivian Harrell',
        email: 'Financial Controller',
        mobile: 'San Francisco',
        rank: '62',
        date: '2009/02/14',
        categoryRank: '452'
      },
      {
        name: 'Timothy Mooney',
        email: 'mobile Manrankr',
        mobile: 'London',
        rank: '37',
        date: '2008/12/11',
        categoryRank: '136'
      },
      {
        name: 'Jackson Bradshaw',
        email: 'Director',
        mobile: 'New York',
        rank: '65',
        date: '2008/09/26',
        categoryRank: '645'
      },
      {
        name: 'Olivia Liang',
        email: 'Support Engineer',
        mobile: 'Singapore',
        rank: '64',
        date: '2011/02/03',
        categoryRank: '234'
      },
      {
        name: 'Bruno Nash',
        email: 'Software Engineer',
        mobile: 'London',
        rank: '38',
        date: '2011/05/03',
        categoryRank: '163'
      },
      {
        name: 'Sakura Yamamoto',
        email: 'Support Engineer',
        mobile: 'Tokyo',
        rank: '37',
        date: '2009/08/19',
        categoryRank: '139'
      },
      {
        name: 'Thor Walton',
        email: 'Developer',
        mobile: 'New York',
        rank: '61',
        date: '2013/08/11',
        categoryRank: '98'
      },
      {
        name: 'Finn Camacho',
        email: 'Support Engineer',
        mobile: 'San Francisco',
        rank: '47',
        date: '2009/07/07',
        categoryRank: '87'
      },
      {
        name: 'Serge Baldwin',
        email: 'Data Coordinator',
        mobile: 'Singapore',
        rank: '64',
        date: '2012/04/09',
        categoryRank: '138'
      },
      {
        name: 'Zenaida Frank',
        email: 'Software Engineer',
        mobile: 'New York',
        rank: '63',
        date: '2010/01/04',
        categoryRank: '125'
      },
      {
        name: 'Zorita Serrano',
        email: 'Software Engineer',
        mobile: 'San Francisco',
        rank: '56',
        date: '2012/06/01',
        categoryRank: '115'
      },
      {
        name: 'Jennifer Acosta',
        email: 'Junior Javascript Developer',
        mobile: 'Edinburgh',
        rank: '43',
        date: '2013/02/01',
        categoryRank: '75'
      },
      {
        name: 'Cara Stevens',
        email: 'Sales Assistant',
        mobile: 'New York',
        rank: '46',
        date: '2011/12/06',
        categoryRank: '145'
      },
      {
        name: 'Hermione Butler',
        email: 'Regional Director',
        mobile: 'London',
        rank: '47',
        date: '2011/03/21',
        categoryRank: '356'
      },
      {
        name: 'Lael Greer',
        email: 'Systems Administrator',
        mobile: 'London',
        rank: '21',
        date: '2009/02/27',
        categoryRank: '103'
      },
      {
        name: 'Jonas Alexander',
        email: 'Developer',
        mobile: 'San Francisco',
        rank: '30',
        date: '2010/07/14',
        categoryRank: '86'
      },
      {
        name: 'Shad Decker',
        email: 'Regional Director',
        mobile: 'Edinburgh',
        rank: '51',
        date: '2008/11/13',
        categoryRank: '183'
      },
      {
        name: 'Michael Bruce',
        email: 'Javascript Developer',
        mobile: 'Singapore',
        rank: '29',
        date: '2011/06/27',
        categoryRank: '183'
      },
      {
        name: 'Donna Snider',
        email: 'Customer Support',
        mobile: 'New York',
        rank: '27',
        date: '2011/01/25',
        categoryRank: '112'
      }
    ]
  };

  return (
    <MDBContainer>
    <MDBDataTable
      striped
      bordered
      small
      data={data}
    />
    </MDBContainer>
  );
}

export default DatatablePrank;