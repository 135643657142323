import Footer from '../navigation/Footer';
import React ,{useEffect, useState}  from 'react';
import Header from './StudentHead';
import { MDBTable, MDBTableBody, MDBTableHead, MDBJumbotron, MDBContainer,MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom'
import firebaseall from '../../firebaseConfig'


const StudentWallet = () => {

      
    const [type , setType]  = useState(Number)
    const [session , setSession]  = useState(Number)
    const [sessionp , setSessionP]  = useState(Number)
    const [typep , setTypeP]  = useState('')
   
   useEffect(()=>{
      try {
            /////////////Free User
        firebaseall.auth().onAuthStateChanged((user)=>{
          firebaseall.database().ref('free-user').child(user.uid).once('value').then((snapshot)=>{    
              // console.log(snapshot.val())
              if(snapshot.val()){
                setSession(snapshot.val().count)
                setType(snapshot.val().type)
              }
              
            })

            ////////////////Paid User /////
           firebaseall.database().ref('paidUser').child(user.uid).on('value',(snapshot)=>{     
            //  console.log(snapshot.val())
            if(snapshot.val()){
              setTypeP(snapshot.val().type)
              setSessionP(snapshot.val().count)
            }
             
            },(e)=>{alert(e)})
          })

      } catch(e){
         alert(e)
      }
  },[])


   
  //  console.log(sessionp)
  //  console.log(typep)

  return (

  <div>
    <Header/>
    <MDBJumbotron className="bannerbg">
            <h3 className='display-5 font-weight-bold mb-0 pt-md-2 text-center text-white'>
                  Wallet
                </h3>
                {/* <h5 className='display-6 mb-0 pt-md-2 text-center text-white'>
                 You can redeem your your histroy here. 
                </h5> */}
          </MDBJumbotron>
        <MDBContainer>
        <MDBTable responsive>
      <MDBTableHead>
        <tr>
          <th className="font-weight-bold">S.No</th>
          <th className="font-weight-bold">Plan</th>
          <th className="font-weight-bold">Remaining sessions</th>
          <th className="font-weight-bold">Redeem</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <td>1</td>
          <td>Plan {type}</td>
          <td>{session}</td>
          <td>{ session === 0 ? <Link   to="/student/wallet"   >  <MDBBtn color="amber" > Redeem </MDBBtn></Link> :  <Link   to="/student/request"   >  <MDBBtn color="amber" > Redeem </MDBBtn></Link> } </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Plan {typep}</td>
          <td>{sessionp}</td>
          <td>{ sessionp === 0 ? <Link   to="/student/wallet"   >  <MDBBtn color="amber" > Redeem </MDBBtn></Link> :  <Link   to="/student/request"   >  <MDBBtn color="amber" > Redeem </MDBBtn></Link> } </td>
        </tr>
        {/* <tr>
          <td>2</td>
          <td>Plan 149</td>
          <td>0</td>
          <td><Link to="/student/request"><MDBBtn color="amber">Redeem</MDBBtn></Link></td>
        </tr>
        <tr>
          <td>3</td>
          <td>Plan 349</td>
          <td>0</td>
          <td><Link to="/student/request"><MDBBtn color="amber">Redeem</MDBBtn></Link></td>
        </tr> */}
      </MDBTableBody>
    </MDBTable>
        </MDBContainer>
<Footer/>
</div>
 );
}

export default StudentWallet;