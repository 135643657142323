import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Chemistry from "../../image/news/Results.jpg";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            JEE ADVANCED 2020 Result
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className=" mb-4">
                  <img className="m-auto" src={Chemistry} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
                <p className="mb-lg-0 mb-md-5 mb-4">
                  The Indian Institute of Technology, Roorkee will release JEE
                  Advanced 2020 result on 5 October 2020. This year, a total of
                  1,60,831 students appeared for the JEE Advanced 2020
                  examination. According to reports, the JEE Advanced Result is
                  likely to be declared at 10 am on the official website
                  jeeadv.ac.in. The All India Rankings (AIR) of JEE Advanced
                  2020 will be released along with the scoresheet.
                </p>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                <h2>Steps to check JEE Advanced Result 2020:</h2>
                <ul>
                  <li>Step 1: Visit the official website - jeeadv.ac.in</li>

                  <li>Step 2: Click on 'JEE Advanced Result 2020' link</li>

                  <li>
                    Step 3: Enter your registration number, date of birth,
                    mobile number and email id
                  </li>

                  <li>
                    Step 4: JEE Advanced 2020 results will be displayed on the
                    screen. Download and take a print out of the result.
                  </li>
                </ul>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
