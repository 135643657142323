import React from "react";
import { Bar } from "react-chartjs-2";
import { MDBContainer, MDBJumbotron } from "mdbreact";
import Header from "../mentor/MentorHead";
import Footer from "../navigation/Footer";

class ChartsPage extends React.Component {
  state = {
    dataBar: {
      labels: [
        "1-1.6",
        "1.6-2.2",
        "2.2-2.8",
        "2.8-3.3",
        "3.3-3.8",
        "3.8-4.3",
        "4.3-4.8",
        "4.8-5.0",
        "5.0",
      ],
      datasets: [
        {
          label: "Rs 5",
          data: [10, 15, 20, 25, 30, 35, 40, 45, 50],
          backgroundColor: [
            "rgba(255, 134,159,0.4)",
            "rgba(98,  182, 239,0.4)",
            "rgba(255, 218, 128,0.4)",
            "rgba(113, 205, 205,0.4)",
            "rgba(170, 128, 252,0.4)",
            "rgba(255, 177, 101,0.4)",
            "rgba(255, 134,159,0.4)",
            "rgba(98,  182, 239,0.4)",
            "rgba(255, 177, 101,0.4)",
          ],
          borderWidth: 1,
          borderColor: [
            "rgba(255, 134, 159, 1)",
            "rgba(98,  182, 239, 1)",
            "rgba(255, 218, 128, 1)",
            "rgba(113, 205, 205, 1)",
            "rgba(170, 128, 252, 1)",
            "rgba(255, 177, 101, 1)",
            "rgba(255, 134, 159, 1)",
            "rgba(98,  182, 239, 1)",
            "rgba(255, 218, 128, 1)",
          ],
        },
      ],
    },
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };

  render() {
    return (
      <div>
        <Header />
        <MDBJumbotron className="bannerbg">
          <h3 className="display-5 font-weight-bold mb-0 pt-md-2 text-center text-white">
            Rate Card for Payment
          </h3>
          <h5 className="display-6 mb-0 pt-md-2 text-center text-white">
            Payment criteria
          </h5>
        </MDBJumbotron>
        <MDBContainer>
          <h3 className="mt-5">Payout Chart</h3>
          <Bar data={this.state.dataBar} options={this.state.barChartOptions} />
          <p className="mt-5 text-left">
            From left to right -- Rating Given by Student per call
          </p>
          <p className="mt-5 text-left">
            From top to buttom -- Payment generated by system
          </p>
        </MDBContainer>
        <Footer />
      </div>
    );
  }
}

export default ChartsPage;
