import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { Button } from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import Header from './StudentHead';
import Footer from '../navigation/Footer';
import { MDBContainer, MDBInput } from 'mdbreact';
import firebaseall from '../../firebaseConfig'


const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

const useStyles = makeStyles({
  root: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  },
});

const HoverRating = () => {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [inputarea, setInputarea] = React.useState('');
  const [name, setName] = React.useState('');
  const classes = useStyles();
  const history = useHistory()
  const submitrating = (e) => {
    e.preventDefault();
    firebaseall.database().ref('feedback-student').push({
      name, value, inputarea
    })
    history.push('/student/home')
  }

  return (
    <div>
      <Header />
      <MDBContainer className="p-5">
        <div className="text-center mt-5">
          <h1>Please rate mentor</h1>
          <Rating
            name="hover-feedback"
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            required
          />

          {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}

        </div>
        <MDBInput type="textarea" label="Please write your experiance" rows="5" onChange={(e) => setInputarea(e.target.value)} required />
        <Button className="float-left"
          onClick={(e) => submitrating(e)}> Submit </Button>
      </MDBContainer>
      <Footer />
    </div>
  );
}

export default withRouter(HoverRating)