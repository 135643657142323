import React  from "react";
import {MDBRow, MDBCol, MDBIcon, MDBContainer} from "mdbreact";
const Mentorpage = () => {
return (
<div>
   <MDBContainer>
      <section className="text-center my-5">
         <h2 className="h1-responsive font-weight-bold my-5">
            Steps to provide mentorship.
         </h2>
         <MDBRow>
            <MDBCol md="4">
               <MDBIcon icon="bell" size="3x" className="red-text" />
               <h5 className="font-weight-bold my-4">Step 1</h5>
               <p className="grey-text mb-md-0 mb-5">
                  You will recieve a notification through email or phone for mentorship.
               </p>
            </MDBCol>
            <MDBCol md="4">
               <MDBIcon icon="calendar-alt" size="3x" className="cyan-text" />
               <h5 className="font-weight-bold my-4">Step 2</h5>
               <p className="grey-text mb-md-0 mb-5">
                  Let us know your free time slot for mentorship.
               </p>
            </MDBCol>
            <MDBCol md="4">
               <MDBIcon icon="envelope-open" size="3x" className="red-text" />
               <h5 className="font-weight-bold my-4">Step 3</h5>
               <p className="grey-text mb-md-0 mb-5">
                  You will recieve a call or an email by the team instiBuddy for mentor allotment.
               </p>
            </MDBCol>
         </MDBRow>
      </section>
      <section className="text-center my-5 mt-2">
         <MDBRow>
            <MDBCol md="4">
               <MDBIcon icon="comment-alt" size="3x" className="cyan-text" />
               <h5 className="font-weight-bold my-4">Step 4</h5>
               <p className="grey-text mb-md-0 mb-5">
                  After a successful mentorship, you will recieve email including the rating and payment request form.
               </p>
            </MDBCol>
            <MDBCol md="4">
               <MDBIcon icon="file-signature" size="3x" className="orange-text" />
               <h5 className="font-weight-bold my-4">Step 5</h5>
               <p className="grey-text mb-md-0 mb-5">
                  Fill contact us form in order to recieve your mentorship certificate.
               </p>
            </MDBCol>
            <MDBCol md="4">
               <MDBIcon icon="headset" size="3x" className="orange-text" />
               <h5 className="font-weight-bold my-4">Support</h5>
               <p className="grey-text mb-md-0 mb-5">
                  In case of any issue related to payment or technical issues go to support section of your dashboard and fill the form.
               </p>
            </MDBCol>
         </MDBRow>
      </section>
   </MDBContainer>
</div>
);
}
export default Mentorpage;