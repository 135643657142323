import React from 'react';
import {Card} from 'react-bootstrap'
import {MDBIframe} from 'mdbreact';
import InfiniteCarousel from 'react-leaf-carousel';


const Achiever=()=>{

return(
             
          <InfiniteCarousel 
          breakpoints={[
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
          ]}
          dots={false}
    showSides={true}
    sidesOpacity={1}
    sideSize={.1}
    slidesToScroll={4}
    slidesToShow={4}
    scrollOnDevice={true}
    responsive={true}
    autoCycle={true}
        >
          
          <div>
          <Card>
                <Card.Body>  
                      <MDBIframe class="embed-responsive-item" src="https://www.youtube.com/embed/7S8s8UxsVx8"/>
                      <Card.Text className='text-center'>
      
      Everything about IIT Bombay | College Review | instiExpress</Card.Text>
                </Card.Body>
              </Card>
          </div>
          <div>
          <Card>
                <Card.Body>
            <MDBIframe class="embed-responsive-item" src="https://www.youtube.com/embed/33JzAfWYTEw" />
                  <Card.Text className='text-center'>Everything About IIT-BHU | College Review | instiExpress  </Card.Text>
                </Card.Body>
              </Card>
          </div>
          <div>
          <Card>
                <Card.Body>
            <MDBIframe class="embed-responsive-item" src="https://www.youtube.com/embed/YTDx98Ts-iY" />
                  <Card.Text className='text-center'>Everything about IIT-Kanpur | College Review | instiExpress</Card.Text>
                </Card.Body>
              </Card>
          </div>
          <div>
          <Card>
                <Card.Body>  
                      <MDBIframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/v5BFnWEM0Y0"/>
                      <Card.Text className='text-center'>
                      Everything about College of Engineering Pune | College Review | instiExpress</Card.Text>
                </Card.Body>
              </Card>
          </div>
          <div>
          <Card>
                <Card.Body>  
                      <MDBIframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/kvR2PvX_XUA"/>
                      <Card.Text className='text-center'>Everything about Jaypee Institute of Information Technology | College Review | instiExpress</Card.Text>
                </Card.Body>
              </Card>
          </div>
          <div>
          <Card>
                <Card.Body>  
                      <MDBIframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/AEDHLpsHR3U"/>
                      <Card.Text className='text-center'>Everything about VJTI | College Review by Student | instiExpress</Card.Text>
                </Card.Body>
              </Card>
          </div>
      
          
        </InfiniteCarousel>
        )
}

export default Achiever;