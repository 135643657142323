import React, { useState, useEffect, createContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Home from './component/homepage/Home';
import About from './component/about/About';
import College from './component/College';
import News from './component/News';
import Faqs from './component/FaqPage';
import Pricing from './component/Pricing'
import Contact from './component/Contact';
import Popup from './component/mentor/PopupMentor'
import Team from './component/Team';
import MentorTc from './component/CompanyTc'
import Test from './component/Test'
//auth section
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import firebaseall from '../src/firebaseConfig'
import SPrivateRoute from './component/SPrivateRoute'
import MPrivateRoute from './component/MPrivateRoute'
import APrivateRoute from './component/APrivateRoute'
//student section 
import Student from './component/student/Student';
import StudentAccount from './component/student/StudentAccount';
import StudentBody from './component/student/StudentBody';
import StudentContact from './component/student/StudentContact';
import StudentHead from './component/student/StudentHead';
import StudentSignup from './component/student/StudentSignup';
import StudentLogin from './component/student/StudentLogin.js';
import StudentWallet from './component/student/StudentWallet';
import StudentPlan from './component/student/StudentPlan';
import StudentProfile from './component/student/StudentProfile';
import MentorRequest from './component/student/MentorRequest';
import MentorRating from './component/student/MentorRating';
import StudentTerms from './component/student/StudentTerms';
import StudentForgotPassword from './component/student/StudentForgotPassword'
//mentor section
import MentorSignup from './component/mentor/MentorSignup';
import MentorLogin from './component/mentor/MentorLogin';
import Mentor from './component/mentor/Mentor'
import MentorAccount from './component/mentor/MentorAccount';
import MentorHead from './component/mentor/MentorHead'
import MentorBody from './component/mentor/MentorBody'
import MentorTerms from './component/mentor/MentorTerms'
import MentorContact from './component/mentor/MentorContact'
import PopupMentor from './component/mentor/PopupMentor'
import MentorProfile from './component/mentor/MentorProfile';
import MentorPayout from './component/mentor/MentorPayout';
import MentorForgotPassword from './component/mentor/MentorForgotPassword'

//admin section 
import AdminHome from './component/mukul/home'
import AdminSideNav from './component/mukul/sideNavigation'
import AdminTopNav from './component/mukul/topNavigation'
import AdminFooter from './component/mukul/AdminFooter'
import CallRequest from './component/mukul/CallRequest'
import AdminPayments from './component/mukul/Payments'
import AdminMentor from './component/mukul/AdminMentor'
import AdminStudent from './component/mukul/AdminStudent'
import StudentaContact from './component/mukul/StudentContact'
import MentoraContact from './component/mukul/MentorContact'
import IndexContact from './component/mukul/IndexContact'
import AdminBlog from './component/mukul/AdminBlog'
import AdminNews from './component/mukul/News'
import CollegePridictor from './component/mukul/CollegePridictor'

//blog section
import Blog1 from './component/blog/blog1'
import Blog2 from './component/blog/blog2'
import Blog3 from './component/blog/blog3'
//news section
import Article1 from './component/article/article1'
import Article2 from './component/article/article2'
import Article3 from './component/article/article3'
import Article4 from './component/article/article4'
import Article5 from './component/article/article5'
import Article6 from './component/article/article6'

export const AuthContext = createContext();

const App = () => {

   const [currentUser, setcurrentUser] = useState(null)
   const [currentUserEmail, setCurrentUserEmail] = useState();
   const [email, setEmail] = useState(false);
   const [user, setUser] = useState(false);

   //  console.log(rate)
   const array = ["nitj.ac.in", "iitbhu.ac.in", "iith.ac.in", "iitbbs.ac.in", "iitb.ac.in", "iitd.ac.in", "iitdh.ac.in", "iitgn.ac.in",
      "iitbhilai.ac.in", "iitg.ac.in", "iith.ac.in",
      "iiti.ac.in", "iitjammu.ac.in", "iitj.ac.in", "iitk.ac.in", "iitkgp.ac.in",
      "iitm.ac.in", "iitmandi.ac.in", "students.iitmandi.ac.in", "iitpkd.ac.in", "iitp.ac.in", "iitr.ac.in", "bt.iitr.ac.in",
      "iitrpr.ac.in", "iittp.ac.in", "ee.iitism.ac.in", "iitism.ac.in", "mnit.ac.in", "manit.ac.in",
      "mnnit.ac.in", "nita.ac.in", "nitap.ac.in", "nitc.ac.in", "nitdelhi.ac.in", "nitdgp.ac.in",
      "nitgoa.ac.in", "nith.ac.in", "nitjsr.ac.in", "nitk.ac.in", "nitkkr.ac.in", "nitmanipur.ac.in",
      "nitm.ac.in", "nitmz.ac.in", "nitnagaland.ac.in", "nitp.ac.in", "nitpy.ac.in", "nitrr.ac.in", "nitrkl.ac.in",
      "nitsikkim.ac.in", "nits.ac.in", "nitsri.ac.in", "nitt.edu", "nitandhra.ac.in", "nituk.ac.in", "nitw.ac.in", "svnit.ac.in", "vnit.ac.in"
   ]

   const mentorUser = array.includes(String(currentUserEmail))

   useEffect(() => {
      firebaseall.auth().onAuthStateChanged((user) => {

         if (user) {
            setUser(true)
            setEmail(user.emailVerified)
         } else {
            setUser(false)
         }

         if (user) {
            const email = user.email;
            const domain = email.substring(email.lastIndexOf("@") + 1);
            setCurrentUserEmail(domain)

            if (mentorUser) {

               return mentorUser;
            }
            else {
               return setcurrentUser(user)
            }
         }

      })
   }, [])


   if (!email && user) {
      return (
         <AuthContext.Provider >
            <Router>
               <Route component={Popup} />
            </Router>
         </AuthContext.Provider>
      )

   }
   else {

      return (
         <AuthContext.Provider value={{ currentUser, mentorUser }}>
            <Router>
               <Switch> <Route exact path='/' component={Home} /> </Switch>
               <Switch><Route path='/about' component={About} /></Switch>
               <Switch><Route path='/college' component={College} /></Switch>
               <Switch><Route path='/test' component={Test} /></Switch>
               <Switch> <Route path='/news_article' component={News} /> </Switch>
               <Switch>  <Route path='/contact' component={Contact} />   </Switch>
               <Switch>  <Route path='/pricing' component={Pricing} />  </Switch>
               <Switch>  <Route path='/faqs' component={Faqs} />  </Switch>
               <Switch>  <Route path='/team' component={Team} />  </Switch>
               <Switch>  <Route path='/terms' component={MentorTc} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/home' component={Student} />  </Switch>
               <Switch>  <Route path='/student/signup' component={StudentSignup} /> </Switch>
               <Switch>  <Route path='/student/login' component={StudentLogin} /> </Switch>
               <Switch>  <SPrivateRoute path='/student/body' component={StudentBody} /> </Switch>
               <Switch>  <SPrivateRoute path='/student/head' component={StudentHead} /> </Switch>
               <Switch>  <SPrivateRoute path='/student/account' component={StudentAccount} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/contacts' component={StudentContact} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/wallet' component={StudentWallet} /> </Switch>
               <Switch>  <SPrivateRoute path='/student/plan' component={StudentPlan} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/profile' component={StudentProfile} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/terms' component={StudentTerms} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/request' component={MentorRequest} />  </Switch>
               <Switch>  <SPrivateRoute path='/student/mentorrating' component={MentorRating} /> </Switch>
               <Switch>  <Route path='/student/password' component={StudentForgotPassword} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/home' component={Mentor} />  </Switch>
               <Switch>  <Route path='/mentor/signup' component={MentorSignup} /> </Switch>
               <Switch>  <Route path='/mentor/login' component={MentorLogin} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/account' component={MentorAccount} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/body' component={MentorBody} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/head' component={MentorHead} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/contacts' component={MentorContact} />  </Switch>
               <Switch> <MPrivateRoute path='/mentor/popup' component={PopupMentor} />  </Switch>
               <Switch> <MPrivateRoute path='/mentor/rate' component={MentorPayout} />  </Switch>
               <Switch> <Route path='/mentor/terms' component={MentorTerms} />  </Switch>
               <Switch>  <MPrivateRoute path='/mentor/profile' component={MentorProfile} />  </Switch>
               <Switch> <Route path='/mentor/password' component={MentorForgotPassword} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/home' component={AdminHome} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/footer' component={AdminFooter} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/sidenav' component={AdminSideNav} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/topnav' component={AdminTopNav} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/callrequest' component={CallRequest} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/payments' component={AdminPayments} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/mentor' component={AdminMentor} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/student' component={AdminStudent} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/studentcontact' component={StudentaContact} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/mentorcontact' component={MentoraContact} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/indexcontact' component={IndexContact} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/blog' component={AdminBlog} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/rank' component={CollegePridictor} />  </Switch>
               <Switch> <APrivateRoute path='/mukul/news' component={AdminNews} />  </Switch>
               <Switch> <Route path='/blog/blog1' component={Blog1} />  </Switch>
               <Switch> <Route path='/blog/blog2' component={Blog2} />  </Switch>
               <Switch> <Route path='/blog/blog3' component={Blog3} />  </Switch>
               <Switch> <Route path='/news/article1' component={Article1} />  </Switch>
               <Switch> <Route path='/news/article2' component={Article2} />  </Switch>
               <Switch> <Route path='/news/article3' component={Article3} />  </Switch>
               <Switch> <Route path='/news/article4' component={Article4} />  </Switch>
               <Switch> <Route path='/news/article5' component={Article5} />  </Switch>
               <Switch> <Route path='/news/article6' component={Article6} />  </Switch>
            </Router>
         </AuthContext.Provider>
      )
   }

}

export default App