import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Admit from "../../image/news/Admit.png";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            JEE Advanced 2020 admit card released
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className=" mb-4">
                  <img className="m-auto" src={Admit} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
                <p className="mb-lg-0 mb-md-5 mb-4">
                  IIT Delhi has released the admit card for the JEE Advanced —
                  the entrance exam for admission to undergraduate courses
                  across IITs and IISc today. You can check the admit card or
                  hall ticket at the site jeeadv.ac.in.
                </p>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                This year, a total of 1,60,864 students have registered to
                appear for the exam. These are the top-ranking students from the
                JEE Main. Around 2.5 lakh students were eligible to apply,
                however, the number of candidates choosing to appear for JEE
                Advanced has declined. Since the exam is being held amid
                pandemic, IIT-Delhi – the organising institute – has made
                several arrangements to conduct the test amid special
                precautions. This year JEE Advanced will be held in more cities
                than before, subsequently, the number of exam centres have also
                been increased. The number of cities has gone up to 222 from 164
                last year, and the number of exam centres from 600 last year to
                1150 this year.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
