import React from "react";
import Shiv from '../../image/Shiv.jpeg'
import Gaurav from '../../image/team/Gaurav.jpg'
import Aparna from '../../image/team/Aparna.jpg'
import Tejaswi from '../../image/team/Tejaswi.png'
import Praveen from '../../image/team/Praveen.jpg'
import '../../style/Achiever.css'
const CarouselPage = () => {
   return (
      <div>
         <section id="testimonial">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title pb-5">
                        <h2>Advisory Panel Message</h2>
                     </div>
                  </div>
                  <div className="col-12">
                     <div id="testimonialCarousel" className="carousel slide" data-ride="carousel">

                        <ol className="carousel-indicators">
                           <li data-target="#testimonialCarousel" data-slide-to="0" className="active"></li>
                           <li data-target="#testimonialCarousel" data-slide-to="1"></li>
                           <li data-target="#testimonialCarousel" data-slide-to="2"></li>
                           <li data-target="#testimonialCarousel" data-slide-to="3"></li>
                           <li data-target="#testimonialCarousel" data-slide-to="4"></li>
                        </ol>
                        <div className="carousel-inner" role="listbox">

                           <div className="carousel-item active">
                              <div className="carousel-content">
                                 <div className="client-img"><img src={Gaurav} alt="Testimonial Slider" /></div>
                                 <p><i>I think it's a genuine problem area that you guys are addressing.
                                    Happy to help with my understanding around monetizing, product and strategy.</i></p>
                                 <h3><span>-</span>Gaurav Gutgutia<span>-</span></h3>
                                 <h4><span>-</span>Co-founder, COO , GrabbnGo Pvt. Ltd.<span>-</span></h4>
                              </div>
                           </div>

                           <div className="carousel-item">
                              <div className="carousel-content">
                                 <div className="client-img"><img src={Praveen} alt="Testimonial Slider" /></div>
                                 <p><i>I am glad InstiBuddy is solving an important problem in the mentorship space.
                                    For years this space has remain unaddressed or badly addressed.
                                    None of the current market interventions in mentorship have been able to scale themselves well nor are they able to address the real latent needs of our youth. InstiBuddy shows incredible potential and is there to stay for the long haul. This platform is created by youth, for youth and who better can understand the problems of today's generation than the young entrepreneurs that conceived this platform? My best wishes to team! </i></p>
                                 <h3><span>-</span>Praveen B Malla<span>-</span></h3>
                                 <h4><span>-</span>Director, IIT-Bombay Alumni Association and Managing Director, AM innovations<span>-</span></h4>
                              </div>
                           </div>

                           <div className="carousel-item">
                              <div className="carousel-content">
                                 <div className="client-img"><img src={Shiv} alt="Testimonial Slider" /></div>
                                 <p><i>I personally believe that good guidance and counselling have played a huge role in my success.
                                    Right from my selection in IIT, I have desperately tried to contact people for guidance on my college and my department. The confusion and agony that it caused were genuinely frustrating. I was lucky however in being able to get in touch with some seniors who guided me like true well-wishers.
                                    Any effort in this direction will be a great service in the career of the students and in the development of our nation.</i></p>
                                 <h3><span>-</span>Kumar Shivashish<span>-</span></h3>
                                 <h4><span>-</span>IPS Officer and IIT Kharagpur Alumni<span>-</span></h4>
                              </div>
                           </div>

                           <div className="carousel-item">
                              <div className="carousel-content">
                                 <div className="client-img"><img src={Aparna} alt="Testimonial Slider" /></div>
                                 <p><i>InstiBuddy is tackling a problem that requires attention and recognition.
                                    There is a need for practical inputs and guidance for students with respect to several challenges in taking academic and career decisions.
                                    I wish them all success in their efforts.</i></p>
                                 <h3><span>-</span>Aparna Rao<span>-</span></h3>
                                 <h4><span>-</span>Senior Manager and professor IIT Bombay<span>-</span></h4>
                              </div>
                           </div>
                           <div className="carousel-item">
                              <div className="carousel-content">
                                 <div className="client-img"><img src={Tejaswi} alt="Testimonial Slider" /></div>
                                 <p><i>A great initiative to fill a gap that has long been ignored, all the best! </i></p>
                                 <h3><span>-</span>Tejaswi Priyadarshi<span>-</span></h3>
                                 <h4><span>-</span>National Sales Manager , Bombay Hemp Company and alumni XLRI,Jamshedpur<span>-</span></h4>
                              </div>
                           </div>

                           <a className="carousel-control-prev text-white" href="#testimonialCarousel" role="button" data-slide="prev">
                              <i className="fas fa-chevron-left pt-3"></i>
                           </a>
                           <a className="carousel-control-next text-white" href="#testimonialCarousel" role="button" data-slide="next">
                              <i className="fas fa-chevron-right pt-3"></i>
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
      //     <MDBJumbotron className="mt-5">
      //        <h3 className='text-center mb-4' style={{ fontWeight: 'bold' }}>Advisory Panel Message
      // </h3>
      //     <MDBJumbotron className="bannerbg">
      //       <MDBCarousel
      //         activeItem={1}
      //         length={5}
      //         showControls={true}
      //         showIndicators={false}

      //       >
      //         <MDBCarouselInner>
      //           <MDBCarouselItem itemId="1" >
      //             <MDBView className="p-5">
      //             <MDBContainer>
      //             <MDBRow className="p-5">
      //          <MDBCol md="3" className="text-center">
      //                <img  src={Gaurav} style={{
      //             resizeMode: "cover",
      //             height: 150,
      //             width: 150}}  alt="aligment" />
      //             </MDBCol>
      //             <MDBCol md="9" className='text-white '>
      //                <p>I think it's a genuine problem area that you guys are addressing. Happy to help with my understanding around monetizing, product and strategy. 
      //                </p>
      //                <h5>Gaurav Gutgutia</h5>
      //                <h6>Co-founder, COO , GrabbnGo Pvt. Ltd.</h6>
      //             </MDBCol>
      //          </MDBRow>
      //          </MDBContainer>
      //             </MDBView>
      //           </MDBCarouselItem>
      //           <MDBCarouselItem itemId="2">
      //           <MDBView className="p-5">
      //             <MDBContainer>
      //             <MDBRow className="p-5">
      //          <MDBCol md="3" className="text-center">
      //                <img  src={Praveen} style={{
      //             resizeMode: "cover",
      //             height: 150,
      //             width: 150}}  alt="aligment" />
      //             </MDBCol>
      //             <MDBCol md="9" className='text-white'>
      //                <p>I am glad InstiBuddy is solving an important problem in the mentorship space. For years this space has remain unaddressed or badly addressed. None of the current market interventions in mentorship have been able to scale themselves well nor are they able to address the real latent needs of our youth. InstiBuddy shows incredible potential and is there to stay for the long haul. This platform is created by youth, for youth and who better can understand the problems of today's generation than the young entrepreneurs that conceived this platform? My best wishes to team! 
      //                </p>
      //                <h5>Praveen B Malla</h5>
      //                <h6>Director, IIT-Bombay Alumni Association and Managing Director, AM innovations</h6>
      //             </MDBCol>
      //          </MDBRow>
      //          </MDBContainer>
      //             </MDBView>
      //           </MDBCarouselItem>
      //           <MDBCarouselItem itemId="3">
      //           <MDBView className="p-5">
      //             <MDBContainer>
      //             <MDBRow className="p-5">
      //          <MDBCol md="3" className="text-center">
      //                <img  src={Shiv} style={{
      //             resizeMode: "cover",
      //             height: 150,
      //             width: 150}}  alt="aligment" />
      //             </MDBCol>
      //             <MDBCol md="9" className='text-white'>
      //                <p>I personally believe that good guidance and counselling have played a huge role in my success.
      //                   Right from my selection in IIT, I have desperately tried to contact people for guidance on my college and my department. The confusion and agony that it caused were genuinely frustrating. I was lucky however in being able to get in touch with some seniors who guided me like true well-wishers. Their role in my amazing experience at IIT Kharagpur and in the department cannot be overstated.
      //                   Similarly, in my final year, with a Pre-Placement Offer in hand and a desire for the civil services also caused confusion. Hours of browsing Quora and speaking to alums finally led me to pursue a career in the civil services.
      //                   I believe I was fortunate to have such good mentors and guides who were up to date with their knowledge and experience. Many of us aren't. A good quality counselling hub is the need of the hour in a world where change is the only constant.
      //                   Any effort in this direction will be a great service in the career of the students and in the development of our nation.
      //                </p>
      //                <h5>Kumar Shivashish</h5>
      //                <h6>IPS Officer and IIT Kharagpur Alumni</h6>
      //             </MDBCol>
      //          </MDBRow>
      //          </MDBContainer>
      //             </MDBView>
      //           </MDBCarouselItem>
      //           <MDBCarouselItem itemId="4">
      //           <MDBView className="p-5">
      //             <MDBContainer>
      //             <MDBRow className="p-5">
      //          <MDBCol md="3" className="text-center">
      //                <img  src={Aparna} style={{
      //             resizeMode: "cover",
      //             height: 150,
      //             width: 150}}  alt="aligment" />
      //             </MDBCol>
      //             <MDBCol md="9" className='text-white'>
      //                <p>InstiBuddy is tackling a problem that requires attention and recognition. There is a need for practical inputs and guidance for students with respect to several challenges in taking academic and career decisions. I wish them all success in their efforts. 
      //                </p>
      //                <h5>Aparna Rao</h5>
      //                <h6>Senior Manager and professor IIT Bombay</h6>
      //             </MDBCol>
      //          </MDBRow>
      //          </MDBContainer>
      //             </MDBView>
      //           </MDBCarouselItem>
      //           <MDBCarouselItem itemId="5">
      //           <MDBView className="p-5">
      //             <MDBContainer>
      //             <MDBRow className="p-5">
      //          <MDBCol md="3" className="text-center">
      //                <img  src={Tejaswi} style={{
      //             resizeMode: "cover",
      //             height: 150,
      //             width: 150}}  alt="aligment" />
      //             </MDBCol>
      //             <MDBCol md="9" className='text-white'>
      //                <p>A great initiative to fill a gap that has long been ignored, all the best! 
      //                </p>
      //                <h5>Tejaswi Priyadarshi</h5>
      //                <h6>National Sales Manager , Bombay Hemp Company and alumni XLRI,Jamshedpur</h6>
      //             </MDBCol>
      //          </MDBRow>
      //          </MDBContainer>
      //             </MDBView>
      //           </MDBCarouselItem>
      //         </MDBCarouselInner>
      //       </MDBCarousel>
      //     </MDBJumbotron>
      //     </MDBJumbotron>
   );
}

export default CarouselPage;