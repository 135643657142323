import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import Footer from '../navigation/Footer';
import Header from "../navigation/Header";
import firebaseall from '../../firebaseConfig'
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment'



const MentorSignup = () => {
   const history =  useHistory()
  const [name,setname] = useState('')
  const [email,setemail] = useState('')
  const [domain,setDomain] = useState('')
  const [password,setpassword] = useState('')
  const [cpassword,setcpassword] = useState('')
  const [collage,setCollage] = useState('')
  const [branch,setBranch] = useState('')
  const [joinCollage,setJoinCollage] = useState('')
  const [phone , setPhone] = useState('')

  // if(email.match(/^[A-Za-z0-9]+$/)){
  //    return;
  // }else{
  //   alert('fuck @')
  // }
    
     const full = email.trim()+domain.trim();
     const date = moment().format("YYYY-MM-DD hh:mm");
    const submit =(e)=>{
       e.preventDefault()     
       if(password !== cpassword){
        alert('Your password and submitted password do not match.')
       }  else{
           if(full.trim()){
            firebaseall.auth().createUserWithEmailAndPassword(full,password).then((snapshot)=>{
              firebaseall.database().ref('mentor').child(snapshot.user.uid).set({
              name,full,password,collage,branch,joinCollage,phone,date })
            snapshot.user.sendEmailVerification()
          }).catch((e)=>{
             alert(e)
          })
  
           }    
        
       
     }

       setname('');
       setBranch('');
       setCollage('')
       setDomain('');
       setJoinCollage('');
       setcpassword('')
       setpassword('')

    history.push('/mentor/home')
        
  }  

  

  console.log(phone)

  const domains = [ 
    {value:"Select your college domain",text:"Select your college domain"},
    {value:"@nitj.ac.in",text:"@nitj.ac.in" },
    {value:"@iitbhu.ac.in",text:"@iitbhu.ac.in"},
    {value:"@iith.ac.in",text:"@iith.ac.in"},
    {value:"@iitbbs.ac.in",text:"@iitbbs.ac.in"},
    {value:"@iitb.ac.in",text:"@iitb.ac.in"},
    {value:"@iitd.ac.in",text:"@iitd.ac.in"},
    {value:"@iitdh.ac.in",text:"@iitdh.ac.in"},
    {value:"@iitgn.ac.in",text:"@iitgn.ac.in"},
    {value:"@iitbhilai.ac.in",text:"@iitbhilai.ac.in"},
    {value:"@iitg.ac.in",text:"@iitg.ac.in"},
    {value:"@iiti.ac.in",text:"@iiti.ac.in"},
    {value:"@iitjammu.ac.in",text:"@iitjammu.ac.in"},
    {value:"@iitj.ac.in",text:"@iitj.ac.in"},
    {value:"@iitk.ac.in",text:"@iitk.ac.in"},
    {value:"@iitkgp.ac.in",text:"@iitkgp.ac.in"},
    {value:"@iitm.ac.in",text:"@iitm.ac.in"},
    {value:"@iitmandi.ac.in",text:"@iitmandi.ac.in"},
    {value:"@students.iitmandi.ac.in",text:"@students.iitmandi.ac.in"},
    {value:"@iitpkd.ac.in",text:"@iitpkd.ac.in"},
    {value:"@iitp.ac.in",text:"@iitp.ac.in"},
    {value:"@iitr.ac.in",text:"@iitr.ac.in"},
    {value:"@bt.iitr.ac.in",text:"@bt.iitr.ac.in"},
    {value:"@iitrpr.ac.in",text:"@iitrpr.ac.in"},
    {value:"@iittp.ac.in",text:"@iittp.ac.in"},
    {value:"@iitism.ac.in",text:"@iitism.ac.in"},
    {value:"@ee.iitism.ac.in",text:"@ee.iitism.ac.in"},
    {value:"@mnit.ac.in",text:"@mnit.ac.in"},
    {value:"@manit.ac.in",text:"@manit.ac.in"},
    {value:"@mnnit.ac.in",text:"@mnnit.ac.in"},
    {value:"@nita.ac.in",text:"@nita.ac.in"},
    {value:"@nitap.ac.in",text:"@nitap.ac.in"},
    {value:"@nitc.ac.in",text:"@nitc.ac.in"},
    {value:"@nitdelhi.ac.in",text:"@nitdelhi.ac.in"},
    {value:"@nitdgp.ac.in",text:"@nitdgp.ac.in"},
    {value:"@nitgoa.ac.in",text:"@nitgoa.ac.in"},
    {value:"@nith.ac.in",text:"@nith.ac.in"},
    {value:"@nitjsr.ac.in",text:"@nitjsr.ac.in"},
    {value:"@nitk.ac.in",text:"@nitk.ac.in"},
    {value:"@nitkkr.ac.in",text:"@nitkkr.ac.in"},
    {value:"@nitmanipur.ac.in",text:"@nitmanipur.ac.in"},
    {value:"@nitm.ac.in",text:"@nitm.ac.in"},
    {value:"@nitmz.ac.in",text:"@nitmz.ac.in"},
    {value:"@nitnagaland.ac.in",text:"@nitnagaland.ac.in"},
    {value:"@nitp.ac.in",text:"@nitp.ac.in"},
    {value:"@nitpy.ac.in",text:"@nitpy.ac.in"},
    {value:"@nitrr.ac.in",text:"@nitrr.ac.in"},
    {value:"@nitrkl.ac.in",text:"@nitrkl.ac.in"},
    {value:"@nitsikkim.ac.in",text:"@nitsikkim.ac.in"},
    {value:"@nits.ac.in",text:"@nits.ac.in"},
    {value:"@nitsri.ac.in",text:"@nitsri.ac.in"},
    {value:"@nitt.edu",text:"@nitt.edu"},
    {value:"@nitandhra.ac.in",text:"@nitandhra.ac.in"},
    {value:"@nituk.ac.in",text:"@nituk.ac.in"},
    {value:"@nitw.ac.in",text:"@nitw.ac.in"},
    {value:"@svnit.ac.in",text:"@svnit.ac.in"},
    {value:"@vnit.ac.in",text:"@vnit.ac.in"}
  ]

    const colleges = [
      {value:"Select your college",text:"Select your college "},
      {value:"Dr. B. R. Ambedkar National Institute of Technology ",text:"Dr. B. R. Ambedkar National Institute of Technology"},
      {value:"Indian Institute of Technology BHU Varanasi",text:"Indian Institute of Technology (BHU) Varanasi"},
      {value:"Indian Institute of Technology Bhilai ",text:"Indian Institute of Technology Bhilai"},
      {value:"Indian Institute of Technology Bhubaneswar", text:"Indian Institute of Technology Bhubaneswar"},
      {value:"Indian Institute of Technology Bombay",text:"Indian Institute of Technology Bombay"},
      {value:"Indian Institute of Technology Delhi",text:"Indian Institute of Technology Delhi"},
      {value:"Indian Institute of Technology Dharwad",text:"Indian Institute of Technology Dharwad"},
      {value:"	Indian Institute of Technology Gandhinagar",text:"Indian Institute of Technology Gandhinagar"},
      {value:"Indian Institute of Technology Goa",text:"Indian Institute of Technology Goa"},
      {value:"	Indian Institute of Technology Guwahati",text:"Indian Institute of Technology Guwahati"},
      {value:"Indian Institute of Technology Hyderabad",text:"Indian Institute of Technology Hyderabad"},
      {value:"	Indian Institute of Technology Indore",text:"Indian Institute of Technology Indore"},
      {value:"	Indian Institute of Technology Jammu",text:"Indian Institute of Technology Jammu"},
      {value:"Indian Institute of Technology Jodhpur",text:"Indian Institute of Technology Jodhpur"},
      {value:"Indian Institute of Technology Kanpur",text:"Indian Institute of Technology Kanpur"},
      {value:"Indian Institute of Technology Kharagpur",text:"Indian Institute of Technology Kharagpur"},
      {value:"Indian Institute of Technology Madras",text:"Indian Institute of Technology Madras"},
      {value:"	Indian Institute of Technology Mandi",text:"Indian Institute of Technology Mandi"},
      {value:"Indian Institute of Technology Palakkad",text:"Indian Institute of Technology Palakkad"},
      {value:"Indian Institute of Technology Patna",text:"Indian Institute of Technology Patna"},
      {value:"Indian Institute of Technology Roorkee",text:"Indian Institute of Technology Roorkee"},
      {value:"Indian Institute of Technology Ropar",text:"Indian Institute of Technology Ropar"},
      {value:"Indian Institute of Technology Tirupati",text:"Indian Institute of Technology Tirupati"},
      {value:"Indian School of Mines Dhanbad ",text:"Indian School of Mines Dhanbad"},
      {value:"Malaviya National Institute of Technology",text:"Malaviya National Institute of Technology"},
      {value:"Maulana Azad National Institute of Technology Bhopal",text:"Maulana Azad National Institute of Technology Bhopal"},
      {value:"Motilal Nehru National Institute of Technology Allahabad",text:"Motilal Nehru National Institute of Technology Allahabad"},
      {value:"National Institute of Technology Agartala",text:"National Institute of Technology Agartala"},
      {value:"National Institute of Technology Arunachal Pradesh ",text:"National Institute of Technology Arunachal Pradesh"},
      {value:"National Institute of Technology Calicut",text:"National Institute of Technology Calicut"},
      {value:"National Institute of Technology Delhi",text:"National Institute of Technology Delhi"},
      {value:"National Institute of Technology Durgapur",text:"National Institute of Technology Durgapur"},
      {value:"National Institute of Technology Goa",text:"National Institute of Technology Goa"},
      {value:"National Institute of Technology Hamirpur",text:"National Institute of Technology Hamirpur"},
      {value:"National Institute of Technology Jamshedpur",text:"National Institute of Technology Jamshedpur"},
      {value:"National Institute of Technology Karnataka Surathkal",text:"National Institute of Technology Karnataka, Surathkal"},
      {value:"National Institute of Technology Kurukshetra",text:"National Institute of Technology Kurukshetra"},
      {value:"National Institute of Technology Manipur",text:"National Institute of Technology Manipur"},
      {value:"National Institute of Technology Meghalaya",text:"National Institute of Technology Meghalaya"},
      {value:"National Institute of Technology Mizoram",text:"National Institute of Technology Mizoram"},
      {value:"National Institute of Technology Nagaland",text:"National Institute of Technology Nagaland"},
      {value:"National Institute of Technology Patna",text:"National Institute of Technology Patna"},
      {value:"National Institute of Technology Puducherry",text:"National Institute of Technology Puducherry"},
      {value:"National Institute of Technology Raipur",text:"National Institute of Technology Raipur"},
      {value:"National Institute of Technology Rourkela",text:"National Institute of Technology Rourkela"},
      {value:"National Institute of Technology Sikkim",text:"National Institute of Technology Sikkim"},
     {value:"National Institute of Technology Silchar",text:"National Institute of Technology Silchar"},
     {value:"National Institute of Technology Srinagar",text:"National Institute of Technology Srinagar"},
     {value:"National Institute of Technology Tiruchirappalli",text:"National Institute of Technology Tiruchirappalli"},
     {value:"National Institute of Technology Andhra Pradesh",text:"National Institute of Technology, Andhra Pradesh"},
     {value:"National Institute of Technology, Uttarakhand",text:"National Institute of Technology, Uttarakhand"},
     {value:"National Institute of Technology Warangal",text:"National Institute of Technology, Warangal"},  
     {value:"Sardar Vallabhbhai National Institute of Technology, Surat",text:"Sardar Vallabhbhai National Institute of Technology, Surat"},  
     {value:"Visvesvaraya National Institute of Technology Nagpur",text:"Visvesvaraya National Institute of Technology, Nagpur"}
   ]


return (
  <div>
    <Header/>
<div>
<MDBContainer className="mt-3">
  <MDBRow className="d-flex justify-content-center">
    <MDBCol md="6">
      <form onSubmit={(e)=>submit(e)}>
        <p className="h5 text-center mb-4">Mentor Sign up</p> 
        <div className="orange-text">
        <MDBInput label="Your name" icon="user-alt" group type="text" value={name} onChange={(e)=>setname(e.target.value)}
         validate error="wrong" success="right" required />
         <MDBRow>
          <MDBCol><MDBInput label="Enter college email and select domain"  value={email} onChange={(e)=>setemail(e.target.value)}
          icon="envelope" group type="text" validate error="wrong" success="right"  required/> 
          
          </MDBCol>
          <MDBCol> 
            <select  value={domain} onChange={(e)=>setDomain(e.target.value)} className="browser-default custom-select" required>
            {
                  domains.map((domain)=>{
                    return <option key={domain.value}> {domain.text} </option>
                  })
               }
          </select>
     </MDBCol>
          </MDBRow>
          <p className='text-danger ml-2'>Provide your college email id...(the former part of email id)
Example:- pkamal in pkamal@iitb.ac.in.</p>
<p className='text-danger ml-2'>If your college domain is not listed, please reach us out through <a href="/contact"><em>contact us</em></a> section or feel free to mail at <a href="mailto:instibuddy@gmail.com">instibuddy@gmail.com</a></p>
<MDBInput label="Your mobile number" icon="mobile" group type="phone" validate error="wrong"
         value={phone} onChange={(e)=>setPhone(e.target.value)}  success="right"  required/>
          <MDBInput label="Your password" icon="lock" group type="password" value={password} onChange={(e)=>setpassword(e.target.value)} validate  required />
          <MDBInput label="Confirm password" icon="lock" group type="password" value={cpassword} onChange={(e)=>setcpassword(e.target.value)} validate required />
            <select className="browser-default custom-select" value={collage} onChange={(e)=>setCollage(e.target.value)} required >
              {
                colleges.map((college)=>{
                return <option key={college.value}> {college.text} </option>
                })
              }
           </select>
           <MDBInput label="Mentorship provided to branch" icon="bold" group type="text" validate error="wrong" success="right" value={branch} onChange={(e)=>setBranch(e.target.value)}  required />
            <MDBInput label="Year of joining college" icon="calendar" group type="number" validate error="wrong" success="right" value={joinCollage} onChange={(e)=>setJoinCollage(e.target.value)} required />
            <MDBInput label="Enter referral code" icon="share-alt" group type="text" validate error="wrong"success="right"  />
            </div>
            <div className="custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" id="defaultUnchecked" required></input>
        <label className="custom-control-label" for="defaultUnchecked">I agreee to the <a href='/mentor/terms' className="text-danger">terms and conditions</a></label>
      </div>
        <div className="text-center">
          <MDBBtn className="instibuddy-bg text-white" type='submit' >Register</MDBBtn>
        </div>
      </form>
    </MDBCol>
  </MDBRow>
</MDBContainer>
<Footer/>
     </div>
     </div>
  );
} 

export default withRouter(MentorSignup);