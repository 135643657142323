import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Chemistry from "../../image/news/Chemistry.png";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            How To Ace Chemistry in JEE
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className=" mb-4">
                  <img className="m-auto" src={Chemistry} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
                <p className="mb-lg-0 mb-md-5 mb-4">
                  If you expect good marks in this subject, you’ll have to focus
                  on fundamental concepts. This is a subject that requires a
                  high amount of information retainment. Chemistry is divided
                  into three parts:
                  <ul className="ml-3">
                    <li>Physical Chemistry</li>
                    <li>Inorganic Chemistry</li>
                    <li>Organic Chemistry</li>
                  </ul>
                </p>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                For being perfect in solving the questions from physical
                chemistry, you need to be perfect in analyzing data and dealing
                with numbers. This subject doesn’t require you to memorize a lot
                of data. You have to learn a few formulae for each topic and
                practise doing calculations at a faster pace. You can also make
                some flow charts to understand the concepts. Inorganic and
                Organic chemistry, both demand a lot of memorization. You have
                to practice remembering hundreds of basic reactions. For organic
                chemistry, you’ll have to learn the function of a lot of
                reagents. These reagents will be asked in the paper of JEE Main
                and Advanced, in a group. So, you can create short cheat sheets
                that contain information about these reagents. You can keep
                these cheat sheets in your pocket while travelling or studying
                the subject. Whenever you feel like learning the reagents, you
                can do it without any problem.
                <hr></hr>
                <h4>Books you can use for Physical Chemistry:</h4>
                <ul className="ml-3">
                  <li>Physical Chemistry by O.P. Tandon</li>
                  <li> NCERT XI and XII Chemistry</li>
                  <li> Atkins Physical Chemistry</li>
                </ul>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
