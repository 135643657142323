import React, { useState }  from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow, MDBIcon, MDBCardHeader } from 'mdbreact';
import Header from "./StudentHead";
import Footer from '../navigation/Footer';
import { MDBJumbotron  } from "mdbreact";
import firebaseall from '../../firebaseConfig' 



const loadScript =(src)=>{
           
  return new Promise ((resolve)=>{
      
    const script = document.createElement('script')
    script.src = src 
    script.onload = ()=>{
      resolve(true)
    }
    
    script.onerror=()=>{
      resolve(false)
    }

    document.body.appendChild(script)

  })  

} 

const StudentPlan = () => {

    const [email , setEmail] = useState('')
        
   // first payment

  const  displayRazorpayf = async  ()=> {
  
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		const data = await fetch('/razorpayf', { method: 'POST' }).then((t) =>
			t.json()
		)

 
 
    const options = {
			key_id: 'rzp_live_HLqD0u661GvSJy',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: 'Just Information',
			description: 'Thanks for choosing Us',
			handler:  (response) => {
				// alert(response.razorpay_payment_id)
				// alert(response.razorpay_order_id)
        // alert(response.razorpay_signature)
          const payid = response.razorpay_payment_id
          const ordid = response.razorpay_order_id
          const sig = response.razorpay_signature

        firebaseall.auth().onAuthStateChanged((user)=>{
           const email = user.email
           setEmail(email)
          firebaseall.database().ref('paidUsers').child(user.uid + '/' + '99').push({
           payid,ordid,sig,email
         }).then(()=>{
           firebaseall.database().ref('paidUser').child(user.uid).set({
             count:1,type:'paid'
           })
         })
        
          //   const email = user.email.substring(0,user.email.indexOf('@'))
        //   firebaseall.database().ref('paidUser').child('99').child(email).child(user.uid).push({
        //     email, payid,ordid,sig,type:99
        //  }).then(()=>{
        // //  const key = firebaseall.database().ref(user.uid).push().key;
        // //  const  updated = {}
        // //   updated[key]={count:3}
        //   firebaseall.database().ref(user.uid).push({
        //     count:1
        //   })
        //  })
        })
			},
			prefill: {
				email 
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()

   }


   ////second payment 

  const  displayRazorpays = async  ()=> {
  
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		const data = await fetch('/razorpays', { method: 'POST' }).then((t) =>
			t.json()
		)

 
 
    const options = {
			key_id: 'rzp_live_HLqD0u661GvSJy',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: 'Just Information',
			description: 'Thanks for choosing Us',
			handler:  (response) => {
				// alert(response.razorpay_payment_id)
				// alert(response.razorpay_order_id)
        // alert(response.razorpay_signature)
          const payid = response.razorpay_payment_id
          const ordid = response.razorpay_order_id
          const sig = response.razorpay_signature
          firebaseall.auth().onAuthStateChanged((user)=>{
            const email = user.email
            setEmail(email)
           firebaseall.database().ref('paidUsers').child(user.uid +'/'+'249').push({
            payid,ordid,sig,email
          }).then(()=>{
            firebaseall.database().ref('paidUser').child(user.uid).set({
              count:3,type:'paid'
            })
          })
         })
			},
			prefill: {
				email
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()

   }
  
   /////  third payment

  const  displayRazorpayt = async  ()=> {
  
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		const data = await fetch('/razorpayt', { method: 'POST' }).then((t) =>
			t.json()
		)

 
 
    const options = {
			key_id: 'rzp_live_HLqD0u661GvSJy',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: 'Just Information',
			description: 'Thanks for choosing Us',
			handler:  (response) => {
				// alert(response.razorpay_payment_id)
				// alert(response.razorpay_order_id)
        // alert(response.razorpay_signature)
          const payid = response.razorpay_payment_id
          const ordid = response.razorpay_order_id
          const sig = response.razorpay_signature
          firebaseall.auth().onAuthStateChanged((user)=>{
            const email = user.email
            setEmail(email)
           firebaseall.database().ref('paidUsers').child(user.uid +'/'+'349').push({
            payid,ordid,sig,email
          }).then(()=>{
            firebaseall.database().ref('paidUser').child(user.uid).set({
              count:5,type:'paid'
            })
          })
         })
			},
			prefill: {
				email
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()

   }

  return (
    <div>
    <Header/>
    <MDBJumbotron className="bannerbg">
            <h3 className='display-5 font-weight-bold mb-0 pt-md-3 text-center text-white'>
                  Plans & pricing
                </h3>
                {/* <h5 className='display-6 mb-0 pt-md-2 text-center text-white'>
                Choose any plan to get mentorship</h5> */}
   </MDBJumbotron>
    <div  className='container mt-5'>
    <MDBRow >


    <MDBCol md='4'style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
        <MDBCardHeader className="text-center font-weight-bold mb-3">1 session/₹99
          <p>Actual price: ₹200</p> 
            <p><mark>Discount@50%</mark></p>
          </MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  1 video call with a Mentor for Unlimited time duration 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg" href="#" style={{ display:'flex', justifyContent:'center' }} onClick={displayRazorpayf} >Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>



    <MDBCol md='4'style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
        <MDBCardHeader className="text-center font-weight-bold mb-3">3 sessions/ ₹249
          <p>Actual price: ₹600 </p> 
            <p><mark>Discount@58%</mark></p></MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  3 video calls with a Mentor for Unlimited time duration 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg" href="#"  style={{ display:'flex', justifyContent:'center' }}  onClick={displayRazorpays}  >Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
      

    <MDBCol md='4'style={{ display:'flex', justifyContent:'center' }}>
      <MDBCard> 
        <MDBCardBody className="paddingg">
        <MDBCardHeader className="text-center font-weight-bold mb-3">5 sessions/ ₹349
          <p>Actual price: ₹1000 </p> <p><mark>Discount@65%</mark></p></MDBCardHeader>
          <ul className="list-unstyled list-inline ml-2 mr-2">
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" />  5 video calls with a Mentor for Unlimited time duration 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Personalized Mentorship 
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> College Guidance
                </li>
                <li className="list-item">
                  <MDBIcon icon="long-arrow-alt-right" className="blue-text" /> Interaction with mentors from IITs and NITs
                </li>
                </ul>
          <MDBBtn className="instibuddy-bg ml-2 mr-2" href="#" style={{ display:'flex', justifyContent:'center' }} onClick={displayRazorpayt}  >Buy Now</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    </MDBRow>
    </div>
  
    <Footer/>
    </div>
  )
 }


export default StudentPlan ;