import React from 'react';
import Footer from '../navigation/Footer';
import { MDBJumbotron  } from "mdbreact";
import Header from '../navigation/Header';

const StudentTerms = () => {
  return (
    <div>
      <Header/>
<div>
          <MDBJumbotron className="bannerbg">
            <h2 className='font-weight-bold mb-0 pt-md-3 text-center text-white'>
            Terms and Conditions For Student
                </h2>
          </MDBJumbotron>
<div className="container">
<p>Welcome to instibuddy.in!</p>
<p>These terms and conditions outline the rules and regulations for the use of Instibuddy App's Website, located at instibuddy.in.</p>
<p>By accessing this website we assume you accept these terms and conditions. Do not continue to use instibuddy.in if you do not agree to take all of the terms and conditions stated on this page.</p>

 <ul className="ml-3">
    <li>No use of foul language</li>
    <li>We use our reasonable endeavours to ensure the accuracy of the information provided on the instiBuddy.in service.</li>
    <li>We do not warrantee or guarantee the accuracy, reliability or legality of any of the information contained in the instiBuddy.in</li>
    <li>We make no warranty that the contents of this site are free from infection by viruses or anything else which has contaminating or destructive properties.</li>
    <li>We reserve the right, at its discretion, from time to time to make changes to the information provided on the instiBuddy.in service, or to alter the nature of the instiBuddy.in service.</li>
    <li>Will not be liable to you / user under any circumstances whatsoever for any direct, indirect, consequential, punitive or special damages arising from your use of the instiBuddy.in service.</li>
    <li>Mentees will not ask personal  details of mentors like mobile number, email id etc</li>
    <li>Users are permitted to read, print or download text, data and/or graphics from the site, for their personal and non-commercial use only. Any copies of these pages saved to disk or to any other storage medium be only used for subsequent viewing purposes or to print extracts for personal use.</li>
    <li>By accessing our Site, you permit us to put our cookies on your hard disc subject. You may, if you please, refuse cookies sent by us.
instiBuddy.in may provide, through this Site, links to Web sites operated by others. If you decide to visit any linked site, you will be doing so at your sole risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements.</li>
<li>Promotion of any third party product is not allowed</li>
<li>Any confidential information of this app must not leak</li>
<li>These terms shall be governed by and constructed under the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts of Gopalganj, Bihar.</li>
</ul>

</div>
 <Footer/>
     </div>
   </div>  
  );
}

export default StudentTerms;