import React from "react";
import Header from "../mentor/MentorHead";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBJumbotron,
} from "mdbreact";
import Footer from "../navigation/Footer";

const ContactMentor = () => {
  return (
    <div>
      <Header />
      <div>
        <MDBJumbotron className="bannerbg">
          <h3 className="display-5 font-weight-bold mb-0 pt-md-2 text-center text-white">
            Contact Us
          </h3>
          <h5 className="display-6 mb-0 pt-md-2 text-center text-white">
            Question? Comments! Suggestions? We are always here to help you.
          </h5>
        </MDBJumbotron>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="6">
              <form>
                <p className="h5 text-center mb-4">Write to us</p>
                <div className="grey-text">
                  <MDBInput
                    label="Your name"
                    icon="user"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <MDBInput
                    label="Your email"
                    icon="envelope"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <MDBInput
                    label="Subject"
                    icon="tag"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <MDBInput
                    type="textarea"
                    rows="2"
                    label="Your message"
                    icon="pencil-alt"
                  />
                </div>
                <div className="text-center">
                  <MDBBtn outline color="instibuddy-bg">
                    Send
                    <MDBIcon far icon="paper-plane" className="ml-1" />
                  </MDBBtn>
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </div>
    </div>
  );
};

export default ContactMentor;
