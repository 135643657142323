import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBJumbotron } from 'mdbreact';
import Footer from '../navigation/Footer';
import React, { useState }  from 'react';
import Header from './StudentHead';
import firebaseall from '../../firebaseConfig';


const ContactUs = () => {
  const [name ,setName] = useState('')
  const [email ,setEmail] = useState('')
  const [subject ,setSubject] = useState('')
  const [massage ,setMassage] = useState('')

  // console.log(name)
  // console.log(email)
  // console.log(subject)
  // console.log(massage)

  firebaseall.auth().onAuthStateChanged((users)=>{
    firebaseall.database().ref('users').child(users.uid).once('value').then((snap)=>{
      //  console.log(snap.val())
       if(snap.val()){
        setName(snap.val().name)
        setEmail(snap.val().email)
       }
       
      
    })
    
  })

  const submit = (e) => {
     e.preventDefault()
         
     firebaseall.database().ref('contact-student').push({
       name,email,subject,massage
     })
 
     setTimeout(()=>{
      alert('We will be contacting you Soon')
     },1000)  
  }


return (
  <div>
    <Header/>
  <div>
          <MDBJumbotron className="bannerbg">
            <h3 className='display-5 font-weight-bold mb-0 pt-md-2 text-center text-white'>
                  Contact Us
                </h3>
                <h5 className='display-6 mb-0 pt-md-2 text-center text-white'>
                  Question? Comments! Suggestions?{' '}
                  We are always here to help you. 
                </h5>
          </MDBJumbotron>
      <MDBContainer>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="6">
            <form onSubmit={(e)=>submit(e)}>
              <p className="h5 text-center mb-4">Write to us</p>
              <div className="orange-text">
                <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
                  success="right" value={name} required />
                <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
                  success="right" value={email} required/>
                <MDBInput label="Subject" icon="tag" group type="text" validate error="wrong" 
                success="right" onChange={(e)=>setSubject(e.target.value)} required />
                <MDBInput type="textarea" rows="2" label="Your message" icon="pencil-alt" onChange={(e)=>setMassage(e.target.value)} required />
              </div>
              <div className="text-center">
                <MDBBtn outline color="instibuddy-bg" type='submit'>
                  Send
                  <MDBIcon far icon="paper-plane" className="ml-1" />
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer/>
     </div>
     </div>

  );
}

export default ContactUs;