import React from 'react'
import Studentheader from './StudentHead'
import Student from './StudentBody'
import Footer from '../navigation/Footer'

//This is student homepage.
const Cstudent = ()=>{
    
    return(
           <div>
            <Studentheader/>
            <Student/>
            <Footer/>
            </div>
    )
}

export default Cstudent;