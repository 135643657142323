import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Covid from "../../image/news/Covid.png";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            Students courage to appear for JEE Advanced, despite of Covid-19
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className=" mb-4">
                  <img className="m-auto" src={Covid} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                JEE Aspirants enter the examination centre to appear for JEE
                Advanced, at Ion Digital Zone, in Sector 62, on September 27,
                2020 in Noida, India. The Joint Entrance Examination-Advanced
                (JEE-Adv) is being conducted across the country on Sunday, with
                160,000 students likely to appear for the engineering entrance
                examination this year amid the coronavirus disease pandemic. The
                examination—organised by the Indian Institute of Technology,
                Delhi (IIT-D)—is being held across 222 cities and over 1,000
                examination centres.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
