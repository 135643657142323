import React, { useState } from "react";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Media from 'react-bootstrap/Media';
import '../../style/Header.css'
import girl2 from '../../image/RankPredictor.png'
import firebaseall from "../../firebaseConfig";
import moment from 'moment';

const RankPredictor = () => {

  const [rank, setRank] = useState(' ')
  const [email, setEmail] = useState(' ')
  const [number, setNumber] = useState(' ')
  const time = moment().format("YYYY-MM-DD hh:mm");
  const submit = (e) => {

    e.preventDefault();
    firebaseall.database().ref('rank-predictor').push({
      rank, email, number, time
    })

    setRank(' ')
    setEmail(' ')
    setNumber(' ')

    alert('Thank you for your response. We\'ll revert back to you soon through mail.')

  }

  return (
    <div>
      <Jumbotron className="rankbannerbg">
        <Container>
          <MDBRow>
            <MDBCol md='6'>
              <h3 className="text-left text-black font-weight-bold">College Predictor</h3>
              <Form onSubmit={(e) => submit(e)}>
                <Form.Group controlId="formBasicMarks">
                  <Form.Control type="number" placeholder="Enter your JEE Advanced Rank" value={rank}
                    onChange={(e) => setRank(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" value={email} placeholder="Enter your email-id" onChange={(e) => setEmail(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formBasicMobile">
                  <Form.Control type="number" value={number} placeholder="Enter your Mobile Number" onChange={(e) => setNumber(e.target.value)} required />
                </Form.Group>
                <Button className="instibuddy-bg float-left" type="submit">
                  Submit
                </Button>
              </Form>
            </MDBCol>
            <MDBCol md='6' className="m-auto">
              <Media>
                <img
                  className="d-block mx-auto Rankbannerbgg"
                  src={girl2}
                  alt="Generic placeholder"
                />
              </Media>
            </MDBCol>
          </MDBRow>
        </Container>
      </Jumbotron>
      <MDBContainer display="flex" justifycontent="center" className="mt-5">
        <h3 className='text-center font-weight-bold mb-5'>Videos that might help you</h3>
      </MDBContainer>
    </div>

  );
}

export default RankPredictor;