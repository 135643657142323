import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import Jee from "../../image/news/IIT.png";
const FeaturesPage = () => {
  return (
    <div>
      <Header />
      <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
            JEE Advanced 2020
          </h2>
          <MDBRow>
            <MDBCol md="12" lg="6">
              <div className="mb-4">
                <MDBView hover rounded className=" mb-4">
                  <img className="m-auto" src={Jee} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <div className="d-flex justify-content-between">
                  <a href="#!" className="deep-orange-text">
                    <h6 className="font-weight-bold">
                      <MDBIcon icon="newspaper" className="pr-2" />
                      News
                    </h6>
                  </a>
                  <p className="font-weight-bold dark-grey-text">
                    <MDBIcon far icon="clock" className="pr-2" />
                    27/11/2020
                  </p>
                </div>
                <p className="mb-lg-0 mb-md-5 mb-4">
                  The JEE Advanced are being conducted by the Indian Institute
                  of Technology (IIT) Delhi in two shifts- morning (9 am to 12
                  pm).The candidates had to appear in the exams with face masks,
                  hand sanitisers. Nearly 97.94 per cent of candidates who have
                  registered and paid the fee for entrance have been allocated
                  exam cities among their top three choices.
                </p>
              </div>
            </MDBCol>

            <MDBCol md="12" lg="6">
              <p className="mb-lg-0 mb-md-5 mb-4">
                Over 1.60 lakh (1,60,864) appeared in the entrance held at 1,150
                centres in 222 cities across the country. The NTA has arranged
                more exam centres following the covid-19 guidelines this year.
              </p>
              <p className="mb-lg-0 mb-md-5 mb-4">
                Students who have appeared in the Joint Entrance Examination
                (JEE) Advanced today rated the paper as moderate. Although the
                maths section was extremely tough and physics was lengthy and
                chemistry was on the easier side having questions mostly follow
                NCERT pattern. The paper was easier than the paper last year.
                The number of questions in the papers were kept same i.e.,54.
                Maths portion was mainly focused on algebra and calculus while
                the physics was concentrated with question of rotation, work
                power energy, magnetism and thermodynamics. As per students, the
                preparation at the exam centre was satisfactory, and the
                government guidelines were strictly followed, but many
                candidates faced problems in reaching the examination centre
                using public transport. The entrance result is scheduled to be
                released October 5 and seat allocation will begin from October
                8, as per the statement.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default FeaturesPage;
