import React ,{useEffect, useState}  from 'react'
import { Route} from 'react-router-dom'
import firebaseall from '../firebaseConfig'

const APrivateRoute = ({ component:RouteComponent ,  ...rest })=>{
     
   const [user ,setUser] = useState(false)


    useEffect(() => {  
        firebaseall.auth().onAuthStateChanged((users)=>{
            // console.log(users.email)
              const array = ['gbu.mukul@gmail.com','krishnaqw4141@gmail.com','myselfayushraj@gmail.com']
             array.includes(users?.email) ? setUser(true) : setUser(false)
             
        })
    }, [])
   
    return(
          <Route {...rest} render = {(routeProps)=> 
            !!user ? (  <RouteComponent {...routeProps} /> ) 
            : ( <h3>You are not Admin</h3>   ) 
            } />  
      )      
    
};

export default APrivateRoute;