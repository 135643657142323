import React ,{useEffect, useState} from 'react';
import { MDBDataTable, MDBContainer } from 'mdbreact';
import firebaseall from '../../firebaseConfig'
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';


const DatatablePrank = () => {
  const [studentDate , setStudentData]=  useState([])
   
   useEffect(()=>{

     firebaseall.database().ref('users').once('value',(snap)=>{
      //  console.log((snap.val()))
        if(snap){
          setStudentData(Object.values(snap?.val()))
        }
     })
   },[])
   
   
   
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Mobile',
        field: 'number',
        sort: 'asc',
        width: 200
      },
      
      {
        label: 'Joining date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Rank',
        field: 'rank',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Category Rank',
        field: 'cRank',
        sort: 'asc',
        width: 100
      }
      
    ],
    rows: studentDate 
  };

  return (
    <div className="flexible-content">
    <SideNavigation />
    <TopNavigation />
    <MDBContainer id="content" >
    <h3 className="text-center mt-5">Student registered with us</h3>
    <MDBDataTable
      striped
      bordered
      small
      data={data}
    />
    </MDBContainer>
    <Footer/>
    </div>
  );
}

export default DatatablePrank;