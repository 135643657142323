import React, { useState ,useContext  }  from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBModalFooter, MDBCard, MDBIcon } from 'mdbreact';
import Footer from '../navigation/Footer';
import firebaseall  from '../../firebaseConfig'
import Header from "../navigation/Header";
import { withRouter , Redirect , Link } from 'react-router-dom'
import {AuthContext} from '../../App'

const FormPage = () => {
  const [email,setEmail] = useState('')
  const [password , setPassword] = useState('')

   
   
   const submit =(e)=>{
     e.preventDefault();
     firebaseall.auth().signInWithEmailAndPassword(email, password)
     .catch(function(error) {
      var errorCode = error.code;      
      if (errorCode === 'auth/wrong-password') {
        alert('Wrong password.');
      } else if (errorCode === 'auth/user-not-found') {
        alert('user not found');
      }else{
        alert('invalid email')
      } 
    }); 
    setPassword('')
    setEmail('')
     
    
  }
  
  const { currentUser } = useContext(AuthContext)
  if(currentUser){
      return <Redirect to='/student/home'/>
  } 

 

return (
  <div>
    <Header/>
  <div>
<MDBContainer className="mt-5">
  <MDBRow className="d-flex justify-content-center"> 
    <MDBCol md="6" className="p-2">
    <MDBCard className="p-4">
      <form onSubmit={(e)=>submit(e)}>
        <p className="h5 text-center mt-4 " ><MDBIcon icon="user-tie" className="orange-text" /> Student Sign in</p>
        <div className="orange-text mt-5">
          <MDBInput label="Type your email" 
          value={email} onChange={(e)=>setEmail(e.target.value)}
          icon="envelope" group type="email" validate error="wrong"
            success="right" />
          <MDBInput label="Type your password" 
          value={password} onChange={(e)=>setPassword(e.target.value)}
          icon="lock" group type="password" validate />
        </div>
        <div className="text-center">
          <MDBBtn className="instibuddy-bg text-white text-center" type='submit'>LOGIN</MDBBtn>
        </div>
      </form>
      <MDBModalFooter>
                <div className="font-weight-light">
                  <p>Not a member? <Link
                      to="/student/signup"
                      className="dark-grey-text ml-1 font-weight-bold"
                    >
                      Sign up
                    </Link></p>
                  <p><Link
                  to="/student/password"
                  className="dark-grey-text ml-1 font-weight-bold"
                >
                 Forgot Password?
                </Link></p>
                </div>
              </MDBModalFooter>
              </MDBCard>
    </MDBCol>
  </MDBRow>
</MDBContainer>
<Footer/>
     </div>
     </div>
  );
} 

export default withRouter(FormPage);