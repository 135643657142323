import firebase from 'firebase'

const firebaseConfig = {

    apiKey: "AIzaSyDlXl9ktZTsvijav0pxcbLveNrdrm7EaHM",
    authDomain: "instibuddy-1d71d.firebaseapp.com",
    databaseURL: "https://instibuddy-1d71d.firebaseio.com",
    projectId: "instibuddy-1d71d",
    storageBucket: "instibuddy-1d71d.appspot.com",
    messagingSenderId: "322783600564",
    appId: "1:322783600564:web:a157e8c78b5c25b61b5ce1",
    measurementId: "G-71G7YZHZHJ"
    
  };

  const firebaseall = firebase.initializeApp(firebaseConfig);
  const auth = firebaseall.auth();
  const db = firebaseall.database();
  export { auth ,db }
  export default firebaseall;