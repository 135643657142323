import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';
import Footer from '../navigation/Footer';
import Header from "../navigation/Header";
import { withRouter } from 'react-router-dom'
import firebaseall from "../../firebaseConfig";
import moment from 'moment'


const MenteeSignUp = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setpassword] = useState('')
  const [cpassword, setcpassword] = useState('')
  const [number, setNumber] = useState('')
  const [category, setCategory] = useState('')
  const [cRank, setCRank] = useState('')
  const [rank, setrank] = useState('')
  const [value, setValue] = useState([]);
  const [valuef, setValuef] = useState('');


  useEffect(() => {
    firebaseall.database().ref('paidUser').once('value').then((snapshot) => {
      var array = []
      snapshot.forEach((snap) => {
        snap.forEach((uid) => {
          uid.forEach((id) => {
            id.forEach((d) => {
              array.push(d.val().email)
              setValue(array)
            })
          })
        })
      })
    })
  }, [])


  // console.log(valuef)


  if (value.length > 0) {
    // console.log(value.indexOf(valuef)) 
    if (value.indexOf(valuef) >= 0) {
      alert('you are getting 10 minute')
    } else if (valuef === '') {

    }

  }


  const date = moment().format("YYYY-MM-DD hh:mm");
  const submitForm = (e) => {
    e.preventDefault()
    if (password !== cpassword) {
      alert('use Same password')
    } else if (password.length < 8) {
      alert('password less than 8')
    } else {
      firebaseall.auth().createUserWithEmailAndPassword(email, password).then((users) => {
        firebaseall.database().ref('users').child(users.user.uid).set({
          name, email, number, category, cRank, rank, date
        })
        users.user.sendEmailVerification()
        firebaseall.database().ref('free-user').child(users.user.uid).set({
          count: 1, type: 'free'
        })
        firebaseall.database().ref('feedback').child(users.user.uid).push({
          value: false
        })
      }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // [START_EXCLUDE]
        if (errorCode === 'auth/weak-password') {
          alert('The password is too weak.');
        } else {
          alert(errorMessage);
        }
        // console.log(error);
        // [END_EXCLUDE]
      });

      setName('')
      setEmail('')
      setpassword('')
      setcpassword('')
      setNumber('')
      setCategory('')
      setCRank('')
      setrank('')
    }


  }


  return (

    <div >
      <Header />
      <div >
        <MDBContainer className="mt-5">
          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="6">
              <form onSubmit={(e) => submitForm(e)} >
                <p className="h5 text-center mb-4">Student Sign up</p>
                <div className="orange-text">
                  <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
                    success="right" onChange={(e) => setName(e.target.value)} required />
                  <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
                    success="right" onChange={(e) => setEmail(e.target.value)} required />
                  <MDBInput label="Your password" icon="lock" group type="password" validate onChange={(e) => setpassword(e.target.value)} required />
                  <MDBInput label="Confirm password" icon="lock" group type="password" validate onChange={(e) => setcpassword(e.target.value)} required />
                  <MDBInput label="Your mobile number" icon="mobile" group type="phone" validate error="wrong"
                    success="right" onChange={(e) => setNumber(e.target.value)} required />
                  <select onChange={(e) => setCategory(e.target.value)} className="browser-default custom-select" required>
                    <option>Select your category</option>
                    <option value="General">General</option>
                    <option value="General – PwD( Persons with Disability)">General – PwD( Persons with Disability)</option>
                    <option value="SC(Scheduled Caste)">SC(Scheduled Caste)</option>
                    <option value="SC - PwD( Persons with Disability)">SC - PwD( Persons with Disability)</option>
                    <option value="ST (Scheduled Tribes)">ST (Scheduled Tribes)</option>
                    <option value="ST - PwD( Persons with Disability)">ST - PwD( Persons with Disability)</option>
                    <option value="OBC (Other Backward Classes)">OBC (Other Backward Classes)</option>
                    <option value="OBC - PwD( Persons with Disability)">OBC - PwD( Persons with Disability)</option>
                    <option value="General Economically Weaker Section (EWS)">General Economically Weaker Section (EWS)</option>
                  </select>
                  <MDBInput label="Your category rank(JEE Mains)" icon="check" group type="text" validate error="wrong"
                    success="right" onChange={(e) => setCRank(e.target.value)} required />
                  <MDBInput label="Your category rank(JEE Advance)" icon="check-double" group type="text" validate error="wrong"
                    success="right" onChange={(e) => setrank(e.target.value)} required />
                  <MDBInput label="Enter referral code 'instibuddy' " icon="share-alt" group type="text" validate error="wrong" success="right" onChange={(e) => setValuef(e.target.value)} />
                </div>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="defaultCheckedDisabled" checked disabled></input>
                  <label class="custom-control-label" for="defaultCheckedDisabled">I agreee to the <a href='/student/terms' className="text-danger">terms and conditions</a> of instibuddy.</label>
                </div>
                <div className="text-center">



                  <MDBBtn className="instibuddy-bg text-white" type='submit' >Register</MDBBtn>
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(MenteeSignUp);