import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const BlogPage = () => {
  return (
    <div>
      <Header />
      <MDBCard className="my-1 px-5 pb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBCol md="12">
              <MDBCard reverse>
                <MDBCardBody cascade className="text-center">
                  <h2 className="font-weight-bold">
                    <a href="#!">Making a life-changing situation</a>
                  </h2>
                  <p>
                    Written by
                    <a href="#!">
                      <strong>-Pravind Kumar (Student)</strong>
                    </a>
                    , 21/10/2020
                  </p>
                  <MDBBtn className="btn-fb waves-light">
                    <MDBIcon fab icon="facebook-f" className="pr-2" />
                    Facebook
                  </MDBBtn>

                  <MDBBtn className="btn-tw waves-light">
                    <MDBIcon fab icon="twitter" className="pr-2" />
                    Twitter
                  </MDBBtn>

                  <MDBBtn className="btn-gplus waves-light">
                    <MDBIcon fab icon="google-plus-g" className="pr-2" />
                    Google
                  </MDBBtn>

                  <MDBBtn color="default" className="waves-light">
                    <MDBIcon icon="comments" className="pr-2" />
                    Comments
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
              <MDBContainer className="mt-5">
                <p>
                  Instibuddy has provided a very good platform for knowing about
                  different cultures of the college. It has helped me to decide
                  which branch is best for me.... The mentors are very helpful
                  and easily contactable. Instibuddy has helped us to connect to
                  various top engineering college students in the country, help
                  us research and decide which college and branch is best for us
                </p>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default BlogPage;
