import React from "react";
import { MDBJumbotron, MDBContainer } from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="text-center">
        <MDBJumbotron className="bannerbg">
          <h2 className=" font-weight-bold mb-0 pt-md-3 text-center text-white">
            About Us
          </h2>
          <blockquote className="blockquote mb-0">
            <p className="text-white">
              Our cheif want in life is somebody who will make us do what we
              can.
            </p>
            <footer className="blockquote-footer text-white">
              Ralph Waldo <cite title="Source Title">Emerson</cite>
            </footer>
          </blockquote>
        </MDBJumbotron>
        <MDBContainer>
          <h3 className="display-5 font-weight-bold mb-0 pt-md-3 text-left">
            Why InstiBuddy ?
          </h3>
          <p className="text-left mt-4">
            Once your entrance exams are over, you try to find better colleges
            for you. You require a proper medium to know about everything the
            colleges you wish to get in. Mentors of InstiBuddy will tell you
            what you should choose and will solve all of your doubts through
            live interaction. That's why we say, "If colleges are the planets,
            we are the telescope".
          </p>
          <p className="text-left">
            Similarly, when you get promoted from your 12th grade, you can't
            just search for colleges without a proper medium. There are
            thousands of colleges, and they are present far away from your
            reach. You will need a well-designed medium to have a glimpse at
            these colleges (that you wish to get in). Furthermore, a better
            medium will help you understand the appearance & mechanism of the
            colleges. That is where InstiBuddy comes in for you.
          </p>
          <p className="text-left">
            Once the entrance exams are over, students become anxious about the
            college they wish to get in. Many students are confused between two
            or more colleges and are worried about which one is better for them.
            The score of your result throws you into the maze of decisions.
            Thoughts like, "Should I go for Mechanical Engineering in college A
            or should I perceive Computer Science in College B?" keep striking
            in your head. To get you out of this maze, Mentors from InstiBuddy
            will guide you. You can interact with a Mentor live through our
            app/website and have a proper discussion regarding your doubts. You
            choose the college that you wish to get in (IIT, NIT, BITS or
            another private engineering college) and the Mentor will tell you
            anything you want to know... as well as everything you should know.{" "}
          </p>
          <p className="text-left">
            We'll provide you with a Mentor, with whom you can interact through
            a video call. You can choose to have a video call at a very less
            price. You have to pay less than you do for a cup of coffee in
            coffee shop and, trust us, that fewer amounts of the price will make
            your future secure. It is just like paying a doctor to verify your
            current condition and predict the future of your health. Though
            doctors charge a fee around ₹1,000s only for a checkup that secures
            your health only for a couple of months, we charge a fee only around
            ₹100s for an interaction that can secure your life for years.
          </p>
        </MDBContainer>

        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
