import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from "mdbreact";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const BlogPage = () => {
  return (
    <div>
      <Header />
      <MDBCard className="my-1 px-5 pb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBCol md="12">
              <MDBCard reverse>
                <MDBCardBody cascade className="text-center">
                  <h2 className="font-weight-bold">
                    <a href="#!">Crucial situation seem so light</a>
                  </h2>
                  <p>
                    Written by
                    <a href="#!">
                      <strong>
                        -Himanshu Singh '4th year student, IIT Bombay' (mentor)
                      </strong>
                    </a>
                    , 29/10/2020
                  </p>
                  <MDBBtn className="btn-fb waves-light">
                    <MDBIcon fab icon="facebook-f" className="pr-2" />
                    Facebook
                  </MDBBtn>

                  <MDBBtn className="btn-tw waves-light">
                    <MDBIcon fab icon="twitter" className="pr-2" />
                    Twitter
                  </MDBBtn>

                  <MDBBtn className="btn-gplus waves-light">
                    <MDBIcon fab icon="google-plus-g" className="pr-2" />
                    Google
                  </MDBBtn>

                  <MDBBtn color="default" className="waves-light">
                    <MDBIcon icon="comments" className="pr-2" />
                    Comments
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
              <MDBContainer className="mt-5">
                <p>
                  I am currently in the fourth year of IIT Bombay and I love
                  giving useful bits of advice to my juniors.
                </p>
                <p>
                  I used to do counselling from before but Instibuddy has given
                  me a certificate of Social work which is indeed very useful
                  for me in terms of boosting my CV/resume.
                </p>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <Footer />
    </div>
  );
};

export default BlogPage;
