import React  from "react";
import {  MDBContainer, MDBRow, MDBCol, MDBPagination, MDBPageItem, MDBPageNav  } from "mdbreact";
import Footer from './navigation/Footer'
import '../style/Header.css'
import Jumbotron from 'react-bootstrap/Jumbotron';
import Header from "./navigation/Header";

const CollegeAssociated = () => {
  return (
    <div>
      <Header/>
  	<div className="text-center">
          <Jumbotron className="bannerbg">
            <h2 className=' font-weight-bold mb-0 pt-md-3 text-center text-white'>
                  College Mentor Associated with us{' '}</h2>
      
          </Jumbotron>
       
    <MDBContainer className="mt-5 text-left ">
      <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Dr. B. R. Ambedkar National Institute of Technology	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology (BHU) Varanasi	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Bhilai	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Bhubaneswar	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Bombay	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Delhi	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Dharwad	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Gandhinagar	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Goa	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Guwahati	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Hyderabad	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Indore	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Jammu	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Jodhpur	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Kanpur	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Kharagpur	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Madras	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Mandi	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Palakkad	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Patna	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Roorkee	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Indian Institute of Technology Ropar	</p></MDBCol>
            <MDBCol md="4"><p>	Indian Institute of Technology Tirupati	</p></MDBCol>
            <MDBCol md="4"><p>	Indian School of Mines Dhanbad	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	Malaviya National Institute of Technology	</p></MDBCol>
            <MDBCol md="4"><p>	Maulana Azad National Institute of Technology Bhopal	</p></MDBCol>
            <MDBCol md="4"><p>	M. Nehru National Institute of Technology Allahabad	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Agartala	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Arunachal Pradesh	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Calicut	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Delhi	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Durgapur	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Goa	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Hamirpur	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Jamshedpur	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Karnataka, Surathkal	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Kurukshetra	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Manipur	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Meghalaya	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Mizoram	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Nagaland	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Patna	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Puducherry	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Raipur	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Rourkela	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Sikkim	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Silchar	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology Srinagar	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology Tiruchirappalli	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology, Andhra Pradesh	</p></MDBCol>
            <MDBCol md="4"><p>	National Institute of Technology, Uttarakhand	</p></MDBCol>
            </MDBRow>
            <MDBRow className="h6 display-5 ">
            <MDBCol md="4"><p>	National Institute of Technology, Warangal	</p></MDBCol>
            <MDBCol md="4"><p>	Sardar Vallabhbhai National Institute of Technology, Surat	</p></MDBCol>
            <MDBCol md="4"><p>	Visvesvaraya National Institute of Technology, Nagpur	</p></MDBCol>
            </MDBRow>


      <MDBPagination color="amber" className="d-flex justify-content-center mt-5">
        <MDBPageItem disabled>
          <MDBPageNav aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </MDBPageNav>
        </MDBPageItem>
        <MDBPageItem active>
          <MDBPageNav>
            1 <span className="sr-only">(current)</span>
          </MDBPageNav>
        </MDBPageItem>
        
        <MDBPageItem>
          <MDBPageNav>
            &raquo;
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    </MDBContainer>
    <Footer/>
     </div>
    </div> 
  );
}

export default CollegeAssociated;