import React, {useEffect, useState } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBTable, MDBTableBody, MDBTableHead, MDBContainer } from 'mdbreact';
import firebaseall from '../../firebaseConfig';

  const AdminMentor =() =>{
  
    const [key , setKey] =  useState([]);
    const [value , setValue] =  useState([]);

     useEffect(()=>{
      firebaseall.database().ref('rank-predictor').on('value' ,(snapshot)=>{
        // console.log( Object.keys(snapshot.val()))
        setKey(Object.keys(snapshot.val()))
        // console.log(Object.values(snapshot.val()));
        if(snapshot.val()){
          setValue(Object.values(snapshot.val()))
        }
       
        
      },(e)=>{
        alert(e)
      })

     },[])
  
    return (
        <div className="flexible-content">
          <SideNavigation />
          <TopNavigation />
          <MDBContainer id="content" >
          <h3 className="text-center mt-5">College Prdeictor Data</h3>
     <MDBTable responsive>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Rank</th>
          <th>Time</th>
         
          
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      
           
          {
            value.map((val,i)=>{   
              return  ( 
                <tr key={key[i]} >
                <td>{i+1}</td>
                 <td>{val.email}</td>
                 <td>{val.number}</td>
                 <td>{val.rank}</td>
                 <td>{val.time}</td>
                </tr>
              )
            })
          
          }
      </MDBTableBody>
    </MDBTable>
    </MDBContainer>
          <Footer/>
        
        </div>
    );
}


export default AdminMentor;