import React from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';

const MentorContact = () => {

  return (
    <div className="flexible-content">
      <TopNavigation />
      <SideNavigation />
      <MDBContainer id="content">
        <p className="h5 text-center mb-4 mt-5">Mentor Contact</p>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="6">
            <form>
              <div className="orange-text">
                <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
                  success="right" required />
                <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
                  success="right" required />
                <MDBInput label="Subject" icon="tag" group type="text" validate error="wrong"
                  success="right" required />
                <MDBInput type="textarea" rows="2" label="Your message" icon="pencil-alt" required />
              </div>
              <div className="text-right">
                <MDBBtn outline color="amber" type='submit' >
                  Close
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}


export default MentorContact;