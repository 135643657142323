import React from 'react'
import { Card, Accordion } from 'react-bootstrap'
import { MDBIcon,MDBJumbotron } from 'mdbreact';
import Header from './navigation/Header';
import Footer from './navigation/Footer';

const Faq = () => {
  return (
      <div>
            <Header/>
            <MDBJumbotron className="bannerbg">
   <h1 className='font-weight-bold mb-0 pt-md-3 text-center text-white'>
   Frequently asked questions
   </h1>
</MDBJumbotron>
    <div className='container mt-5'>
      <Accordion defaultActiveKey="0" className="mt-5">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <h6 style={{ fontWeight: "bold" }}>How will your platform - instiBuddy- benefit students?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /> </h6>

          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>When a student is promoted from 12th grade, they will need a well-designed medium to have a glimpse at the colleges that they wish to get in. There are thousands of colleges, and they are present far away from their reach. We are creating a better medium to understand the appearance & mechanism of the colleges before a student decides to get in.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>




          <Accordion.Toggle as={Card.Header} eventKey="1">
            <h6 style={{ fontWeight: "bold" }}>What is the qualification of the mentors available on your platform?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>We will be creating a platform that brings in well-experienced Mentors (who are students from different IITs, NITs, and other top engineering colleges. These mentors will guide the customer in a decision of choosing a college.
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            <h6 style={{ fontWeight: "bold" }}>What kind of doubts should I ask on the video call?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>We have provided a separate section on our website/app that contains a list of question that you can ask the mentor. After going through the list, you will be able to frame your own questions.
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <h6 style={{ fontWeight: "bold" }}>How will instiBuddy be helpful in making a decision of joining a college based on my score? <MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>The score of your result throws you into the maze of decisions. Thoughts like, "Should I go for Mechanical Engineering in college A or should I perceive Computer Science in College B?" keep striking in your head. To get you out of this maze, Mentors from InstiBuddy will guide you. You can interact with a Mentor live through our app/website and have a proper discussion regarding your doubts. You choose the college that you wish to get in (IIT, NIT, BITS or another private engineering college) and the Mentor will tell you anything you want to know... as well as everything you should know.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <h6 style={{ fontWeight: "bold" }}>What will I have to do in order to become a mentor in InstiBuddy?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>You will have to sign up to your Mentor account.
                       You can use a referral code if you have one.
                       After that, you will receive a notification if you have been selected.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            <h6 style={{ fontWeight: "bold" }}>How could I opt for mentorship if I am a student who's confused between 2 or more colleges?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>You can sign up to your mentee account. For getting a free call offer, you can use a referral</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            <h6 style={{ fontWeight: "bold" }}>What should I do if I don't have a referral code and I am a student?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>Then you won’t be able to have a free call. You’ll have to choose a package according to your choice.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            <h6 style={{ fontWeight: "bold" }}>Where should I reach in case I face any technical glitch during my mentorship session?<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>You can go to the “Contact Us” section of our website. There you’ll see some details through which you can contact us.
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="8">
            <h6 style={{ fontWeight: "bold" }}>On what basis should I rate my mentor?
How he/she resolves your problems and answers your questions
<MDBIcon className="ml-5 orange-text float-right align-middle" icon="plus" /></h6>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Body>On the basis of time given by the mentor to you on the video call
Whether the mentor gives you true or false facts
On the basis of politeness of the mentor

</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      
    </div>
    <Footer/>
    </div>
  )
}

export default Faq;