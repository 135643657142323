import React, { useEffect, useState } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBCardGroup, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import firebaseall from '../../firebaseConfig';
import '../../style/admin.css'
import { Button } from 'react-bootstrap';




  const CallRequest = () => {
    const [ datas, setData] = useState([]);
    const [ key, setKeys] = useState([]);
    const [uid , setUid] = useState('');
    const [name ,setName] = useState('');
 
   useEffect(()=>{

     try {
           firebaseall.database().ref('request').once('value').then((snapshot)=>{  
                 setKeys(Object.keys(snapshot.val()))
                //  console.log(Object.keys(snapshot.val()))
                 setData(Object.values(snapshot.val()))
                //  console.log(Object.values(snapshot.val()))
                  snapshot.forEach((sn)=>{
                    setName(sn.val().name)
                    setUid(sn.val().uid)
                    // console.log(sn.val().uid)
                  })
            })

      } catch(e) {
       alert(e)
    }

     },[])

      
    return (
        <div className="flexible-content">
          <SideNavigation />
          <TopNavigation />
          <div className="special-color color-block-5 mx-auto z-depth-1-half">
          <MDBContainer id="content" >
          <MDBCardGroup className="justify-content-center ">
                             {
                                  datas.map((d , i )=>{
                                     console.log(d)
                                    return  (  
                                      <MDBRow key={i}>
                                        <MDBCol md="3"> 
                                    <MDBCard style={{ width: "22rem", marginTop: "1rem" }} key={key[i]} className="m-2"> 
                                    <MDBCardHeader color="unique-color" tag="h3" className="text-center">
                                      Student :  {d.name}
                                    </MDBCardHeader>
                                    <MDBCardBody>
                                    <MDBCardTitle className="font-weight-bold"> Type: {d.type}</MDBCardTitle>
                                    <MDBCardText className="font-weight-bold">
                                       College: {d.collageD}
                                    </MDBCardText>
                                    <MDBCardText className="font-weight-bold">
                                       Branch: {d.branch}
                                    </MDBCardText>
                                    <MDBCardText className="font-weight-bold">
                                       Time: {d.date}
                                    </MDBCardText>
                                    </MDBCardBody>
                                    <MDBRow>
                                      <MDBCol><Button onClick={(e)=>{
                                        e.preventDefault()
                                        if(d.uid){
                                          firebaseall.database().ref('feedback').child(d.uid).set({
                                            value:true
                                          })
                                          alert('send to user') 
                                        }else{
                                          alert('not get uid , so not send to User')
                                        }
      
                                    }} variant="info" > feedback </Button></MDBCol>
                                      <MDBCol>
                                        <Button  variant="info" > Complete</Button>
                                      </MDBCol>
                                      </MDBRow>    
                                </MDBCard></MDBCol>
                                      </MDBRow>
                                   
                                    )
                                  })   
                              } 
                       </MDBCardGroup>
                       
                       </MDBContainer>
                       </div>
          <Footer/>
        </div>
    );
  }

export default CallRequest;