import React, { Component } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

class Payments extends Component {
  
  render() {
    return (
        <div className="flexible-content">
          <SideNavigation />
          <TopNavigation />
          <MDBTable responsive>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Order id</th>
          <th>Payment id</th>
          <th>Amount</th>
          
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <td>1</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          
        </tr>
        <tr>
          <td>2</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          
        </tr>
        <tr>
          <td>3</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
          
        </tr>
      </MDBTableBody>
    </MDBTable>
          <Footer/>
        
        </div>
    );
  }
}

export default Payments;