import React from 'react'
import Body from './Body'
import Faq from  './Faq'
import Blog from  './Bloghome'
import Achiever from  './Achiever'
import Rank from './RankPredictor'
import Introduction from './Introduction'
import Video from './Video'
import TroubleQuestions from './TroubleQuestions'
import Footer from  '../navigation/Footer'
import Header from '../navigation/Header';

const Home =()=>{
    return (
        <div>
            <Header/>
            <Body/>
            <Introduction/>
            <TroubleQuestions/>
            <Achiever/>
            <Rank/>
            {/* <Test/> */}
            <Video/>
            <Blog/>
            <Faq/>
            <Footer/>
         </div>       
    )
}

export default Home