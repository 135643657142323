import React from 'react';
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Shatakshi from '../../image/blog/Shatakshi.png'
import Pravind from '../../image/blog/Pravind.png'
import Himanshu from '../../image/blog/Himanshu.png'

const Bloghome = () => {
  return (
   <div className='container mt-5'>
   <h3 className='text-center' style={{ fontWeight: 'bold' }}>Testimonials</h3>
    <MDBRow className="mt-5">
      <MDBCol md='4'>
        <MDBCard ecommerce>
        <MDBCardImage className="img-fluid" src={Himanshu} waves />
          <MDBCardBody className='elegant-color white-text rounded-bottom'>
            <MDBCardText className='white-text'>
            I am currently in the fourth year of IIT Bombay and I love giving useful bits of advice to my juniors. 
            I used to do counselling from before but  Instibuddy has given me a certificate of Social work which is indeed very useful for me in terms of boosting my CV/resume.
<hr className='hr-light' />
-Himanshu Singh '4th year student, IIT Bombay' (mentor)
            </MDBCardText>
            <a href='blog/blog1' className='black-text d-flex justify-content-end'>
              <h5 className='white-text'>
                Read more
                <MDBIcon icon='angle-double-right' className='ml-2' />
              </h5>
            </a>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md='4'>
        <MDBCard>  
        <MDBCardImage className="img-fluid" src={Shatakshi} waves />
          <MDBCardBody className='elegant-color white-text rounded-bottom'>
            <MDBCardText className='white-text'>
            Instibuddy has been a great experience. I am the only child in my entire
family, who has cleared an exam of this level. So, it was obvious that I
won’t have mentors along with me, who would guide me in such
situation of making a life-changing situation. The mentors of Instibuddy
have been so supportive. They described everything so well. 
<hr className='hr-light' />
-Shatakshi (Student)
            </MDBCardText>
            <a href='blog/blog2' className='black-text d-flex justify-content-end'>
              <h5 className='white-text'>
                Read more
                <MDBIcon icon='angle-double-right' className='ml-2' />
              </h5>
            </a>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md='4'>
      <MDBCard>  
      <MDBCardImage className="img-fluid" src={Pravind} waves />
          <MDBCardBody className='elegant-color white-text rounded-bottom'>
            <MDBCardText className='white-text'>
            Instibuddy has provided a very good platform for knowing about different cultures of the college. It has helped me to decide which branch is best for me....

The mentors are very helpful and easily contactable. Instibuddy has helped us to connect to various top engineering college students in the country, help us research and decide which college and branch is best for us......
<hr className='hr-light' />
-Pravind Kumar (Student)
            </MDBCardText>
            <a href='blog/blog3' className='black-text d-flex justify-content-end'>
              <h5 className='white-text'>
                Read more
                <MDBIcon icon='angle-double-right' className='ml-2' />
              </h5>
            </a>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </div>
  )
}

export default Bloghome;