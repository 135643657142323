import React from 'react'
import {Card,Accordion} from 'react-bootstrap'
const TroubleQuestions = ()=>{
    return (
         <div className='container mt-5'>
         <h3 className='text-center mb-4' style={{ fontWeight: 'bold' }}>Questions that might trouble you</h3>
      <Accordion defaultActiveKey="0">
  <Card>
    <Accordion.Toggle as={Card.Header}>
    <h6 style={{fontWeight: "bold"}}>1. Should I choose a good branch of not-so-good college or not-so-good branch of a good college? </h6>
    </Accordion.Toggle>
   
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header}>
    <h6 style={{fontWeight: "bold"}}>2. What is the average package received by the pass out students of this college? </h6>
    </Accordion.Toggle>
   
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header}>
    <h6 style={{fontWeight: "bold"}}>3. Does the package I receive depend on the branch I choose or the college I have studied in? </h6>
    </Accordion.Toggle>
   
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header}>
     <h6 style={{fontWeight: "bold"}}>4. What is the contribution of the NIRF rank in bagging a package from different companies? </h6>
    </Accordion.Toggle>
   
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header}>
     <h6 style={{fontWeight: "bold"}}>5. What is the average package bagged by the students of this college? </h6>
    </Accordion.Toggle>
   
  </Card>
</Accordion>
</div>

    )
}

export default TroubleQuestions;