import React, { Component } from 'react';
import TopNavigation from './topNavigation';
import SideNavigation from './sideNavigation';
import Footer from './AdminFooter';
import './index.css';
import {MDBContainer } from "mdbreact";

class Blog extends Component {
  
  render() {
    return (
        <div className="flexible-content" >
          <TopNavigation />
          <SideNavigation />
          <MDBContainer id="content">

          </MDBContainer>
          <Footer />
        </div>
    );
  }
}

export default Blog;