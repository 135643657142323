import React, { useEffect, useState }  from "react";
import {MDBRow, MDBCol, MDBIcon, MDBContainer} from "mdbreact";
import { withRouter , useHistory } from 'react-router-dom'
import firebaseall from '../../firebaseConfig'


const Studentpage = () => {
  const history = useHistory()
 const [value , setValue] = useState()
 const [uid , setUid] = useState()

  useEffect(()=>{
   
    firebaseall.auth().onAuthStateChanged((user)=>{
       setUid(user.uid)
      if(user.uid){
        firebaseall.database().ref('feedback').child(user.uid).on('value',(snap)=>{
          //  console.log(snap.val().value)
          if(snap.val()){
            setValue(snap.val().value)
          }
           
        })
      }else{
        return
      }
    })
    

  },[])

  if(value){
    history.push('/student/mentorrating')
    firebaseall.auth().onAuthStateChanged((user)=>{
      // console.log(user.uid)
    firebaseall.database().ref('feedback').child(user.uid).set({
      value:false
    })
   })
  }
 


  return (
  	<div className="text-center">
       
       <MDBContainer>
          <section className="text-center my-5">
        <h2 className="h1-responsive font-weight-bold my-5">
          Steps to talk mentors from top engineering colleges
        </h2>
        <p className="lead grey-text w-responsive mx-auto mb-5">
          Follow these steps to get mentorship
        </p>
        <MDBRow>
          <MDBCol md="4">
            <MDBIcon icon="shopping-cart" size="3x" className="red-text" />
            <h5 className="font-weight-bold my-4">Step 1</h5>
            <p className="grey-text mb-md-0 mb-5">
              Go to plan section and buy a desired pack from plan section and your session will be added to your wallet.
            </p>
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon icon="wallet" size="3x" className="cyan-text" />
            <h5 className="font-weight-bold my-4">Step 2</h5>
            <p className="grey-text mb-md-0 mb-5">
              Go to wallet section and tap on redeem button present there.
            </p>
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon icon="user-graduate" size="3x" className="orange-text" />
            <h5 className="font-weight-bold my-4">Step 3</h5>
            <p className="grey-text mb-md-0 mb-5">
            After tapping the redeem button you will forwarded to mentor request page. Now select your desired college and branch and tap on the request mentor button.
            </p>
          </MDBCol>
        </MDBRow>
      </section>
      <section className="text-center my-5 mt-2">
        <MDBRow>
          <MDBCol md="4">
            <MDBIcon icon="envelope-open" size="3x" className="red-text" />
            <h5 className="font-weight-bold my-4">Step 4</h5>
            <p className="grey-text mb-md-0 mb-5">
            You will receive a call or an email by the team instiBuddy for mentor allotment.
            </p>
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon icon="comment-alt" size="3x" className="cyan-text" />
            <h5 className="font-weight-bold my-4">Step 5</h5>
            <p className="grey-text mb-md-0 mb-5">
            Relogin with your credential to rate your mentor.
            </p>
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon icon="headset" size="3x" className="orange-text" />
            <h5 className="font-weight-bold my-4">Support</h5>
            <p className="grey-text mb-md-0 mb-5">
            In case of any technical glitches go to support section of your dashboard and fill the form.
            </p>
          </MDBCol>
        </MDBRow>
      </section>
      </MDBContainer>
    </div>
  );
}

export default withRouter(Studentpage);