import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import '../../style/Body.css';

const Body = () => {
  return (

    <div >
    <Jumbotron className="homebannerbg mb-0">
      <Container>
      <MDBRow>
         <MDBCol md='6' className="instibuddy-bgtext">
            <h3 className="text-left text-black font-weight-bold">If colleges are Planets, we are the Telescope!
            </h3>
             <p className="text-black">For looking at the galaxies, stars, and planets, you need a Telescope. Similarly, when you are promoted from your 12th grade, you will need a well-designed medium to have a glimpse at the colleges that you wish to get in.</p>
             <p className="text-black">A better medium will be required to understand the appearance & the mechanism of the thousands of existing colleges.</p>
             <div className='button text-left'>
                {/* <Button className="instibuddy-bg" as={Link} to='/student/signup'>Student Sign Up</Button>
                <Button className="instibuddy-bg" as={Link} to='/mentor/signup'>Mentor Sign Up</Button> */}
                 <Button className="instibuddy-bg" rel="noopener noreferrer" href='https://collegebuddy.instibuddy.in/student/signup' target="_blank">Student Sign Up</Button>
                <Button className="instibuddy-bg" rel="noopener noreferrer" href='https://collegebuddy.instibuddy.in/mentor/signup' target="_blank">Mentor Sign Up</Button>
            </div>
         </MDBCol>      
      </MDBRow>
      </Container>
    </Jumbotron>
    </div>
  ); 
}

export default Body;
